import { get } from '@ember/object';
import { inlineSvg } from 'ember-inline-svg/helpers/inline-svg';
import { dottify } from 'ember-inline-svg/utils/general';
import SVGs from '@intercom/pulse/svgs';

let legacyNameMap = {
  team: 'multiple-people',
  'two-users': 'multiple-people',
  user: 'person',
  admin: 'person',
  contact: 'person',
  'all-companies': 'company',
};

export function standardIconClasses(options) {
  let classes = [];

  if (typeof options.color === 'string') {
    classes.push(`o__palette-${options.color}`);
  }

  if (options.solo === undefined || !options.solo) {
    classes.push('o__by-text');
  }

  if (options.noMargin) {
    classes.push('o__no-margin');
  }

  return classes;
}

export function renderInterfaceIcon(iconSet, params, options) {
  options = options === undefined ? {} : options;
  let name = params[0];
  if (legacyNameMap[name] !== undefined) {
    name = legacyNameMap[name];
  }
  let path = `interface-icons/${iconSet}/${name}`;

  if (get(SVGs, dottify(path)) === undefined) {
    // SVG can't be found
    return '';
  }

  let classes = ['interface-icon', `o__${iconSet}`, `o__${iconSet}__${name}`].concat(
    standardIconClasses(options),
  );
  if (iconSet === 'standard' && options.size === 24) {
    classes.push('o__24');
  }

  return inlineSvg(SVGs, path, {
    class: classes.join(' '),
  });
}

export default {
  renderInterfaceIcon,
  standardIconClasses,
};
