import { get, computed } from '@ember/object';

export default function findByProperty(dependentKey, propertyKey, valueKey) {
  return computed(`${dependentKey}.@each.${propertyKey}`, valueKey, function () {
    let collection = get(this, dependentKey);
    if (!collection || !collection.findBy) {
      return;
    }
    return collection.findBy(propertyKey, this.get(valueKey));
  });
}
