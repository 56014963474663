import Component from '@glimmer/component';

export default class TableActions extends Component {
  get inlineActionButtons() {
    return this.args.rowActions.filter((rowAction) => !rowAction.inDropdown);
  }

  get dropdownActionItems() {
    let rawDropdownActionItems = this.args.rowActions.filter(
      (rowAction) => rowAction.inDropdown === true,
    );

    return rawDropdownActionItems.map((item) => ({
      text: item.label,
      tooltipText: item.tooltip,
      icon: item.icon,
      onSelectItem: item.onClick,
      isDisabled: item.disabled,
    }));
  }
}
