import { helper as buildHelper } from '@ember/component/helper';
import { assert, runInDebug } from '@ember/debug';

export function validateArg(params, arg) {
  if (arg) {
    runInDebug(() => {
      assert(
        `<${arg.component}>: "${arg.name}" is a required option.`,
        arg.value !== null && arg.value !== undefined,
      );
    });
  }
}

export default buildHelper(validateArg);
