import $ from 'jquery';
import { schedule } from '@ember/runloop';
import { A } from '@ember/array';
import { readOnly, or, not, equal, filterBy, empty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { fmtStyle, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import ICBaseComponent from '@intercom/pulse/components/ic-base-component';
import { task } from 'ember-concurrency';
import { scheduler as rafScheduler } from 'ember-raf-scheduler';
import { debounceTask, runTask } from 'ember-lifeline';
import trustedStyle from '@intercom/pulse/lib/trusted-style';

const LARGEST_MAX_WIDTH = 1000;

const COLUMN_DEFAULTS = {
  label: undefined,
  propertyName: undefined,
  valueComponent: undefined,
  component: undefined,
  description: undefined,
  icon: undefined,
  canExpandToFit: false,
  sortable: false,
  isSortColumn: false,
  sortedAscending: true,
  usesLinkHelper: false,
  maxWidth: Number.MAX_SAFE_INTEGER,
};

const COLUMN_DEFAULTS_KEYS = Object.keys(COLUMN_DEFAULTS);

const COLUMN_KEYS_FOR_CP = COLUMN_DEFAULTS_KEYS.map((key) => {
  return `columns.@each.${key}`;
}).concat(['columns']);

const FORMAT_COLUMNS_ARGS = COLUMN_KEYS_FOR_CP.concat([
  function () {
    return this.get('columns').map((unformattedColumn) => {
      let column = Object.assign({}, unformattedColumn);
      for (let i = 0, l = COLUMN_DEFAULTS_KEYS.length; i < l; i++) {
        if (column[COLUMN_DEFAULTS_KEYS[i]] === undefined) {
          column[COLUMN_DEFAULTS_KEYS[i]] = COLUMN_DEFAULTS[COLUMN_DEFAULTS_KEYS[i]];
        }
      }
      return column;
    });
  },
]);

const DUMMY_EXPANDABLE_COLUMN = {
  label: '',
  dummy: true,
  canExpandToFit: true,
};

let COLUMN_WIDTH_INFO_KEYS = ['width', 'widthToRender', 'widthStyle'];

export default ICBaseComponent.extend({
  componentClasses: computed('flexHeight', function () {
    return `table__wrapper relative flex flex-col ${this.get('flexHeight') ? 'flex-auto' : ''}`;
  }),
  attributeBindings: ['data-test-legacy-table-wrapper', 'style'],
  'data-test-legacy-table-wrapper': true,
  height: 500,
  inline: false,
  browserEnvironment: service('ic-browser-environment'),
  scrollbarWidth: readOnly('browserEnvironment.scrollbarWidth'),
  scrollThreshold: 20,
  widthsReadFromHeader: false,
  initialRefreshComplete: false,
  roundedWidth: '100%',
  style: fmtStyle('height: %@;', 'formattedHeight'),
  maxWidthStyle: fmtStyle('max-width: %@;', 'roundedWidth'),
  tableIsVisible: false,
  headerPanelIsVisible: or('tableIsVisible', 'autoHeight'),
  formattedHeight: computed('height', function () {
    let height = this.get('height');
    if (typeof height === 'number') {
      return `${height}px`;
    }
    if (height === 'flex') {
      return 'auto';
    } else {
      return height;
    }
  }),
  dynamicRowHeight: false,
  staticHeightVerticalCollectionProperty: not('dynamicRowHeight'),
  flexHeight: equal('height', 'flex'),
  autoHeight: equal('height', 'auto'),
  formattedColumns: computed(...FORMAT_COLUMNS_ARGS),
  formattedColumnsWithDummyExpandableColumn: computed('formattedColumns', function () {
    let columns = this.get('formattedColumns');
    if (typeof columns.toArray === 'function') {
      columns = columns.toArray();
    }
    return columns.concat([Object.assign({}, DUMMY_EXPANDABLE_COLUMN)]);
  }),
  expandableColumns: filterBy('columns', 'canExpandToFit', true),
  noExpandableColumns: empty('expandableColumns'),
  fullColumns: ternaryToProperty(
    'noExpandableColumns',
    'formattedColumnsWithDummyExpandableColumn',
    'formattedColumns',
  ),
  fullColumnsWithSettings: computed('fullColumns', 'columnWidthInfo', function () {
    let fullColumnsWithSettings = A();
    let fullColumns = this.get('fullColumns');
    let columnWidthInfo = this.get('columnWidthInfo');
    fullColumns.forEach(function (item, index) {
      fullColumnsWithSettings[index] = Object.assign({}, fullColumns[index]);
      for (let i = 0, l = COLUMN_WIDTH_INFO_KEYS.length; i < l; i++) {
        let key = COLUMN_WIDTH_INFO_KEYS[i];
        if (columnWidthInfo[index] !== undefined) {
          fullColumnsWithSettings[index][key] = columnWidthInfo[index][key];
        }
      }
    });
    return fullColumnsWithSettings;
  }),
  alternateTableContentWidth: computed('inline', function () {
    if (this.isDestroying) {
      return 0;
    }
    return Math.floor($(this.element).width()) - (this.get('inline') ? 0 : 2);
  }),

  init() {
    this._super(...arguments);
    this.set('columnWidthInfo', A());
  },
  didInsertElement() {
    this._super(...arguments);
    schedule('afterRender', () => {
      if (this.isDestroying) {
        return;
      }
      this.adjustRoundedWidth();
      this.readWidthsFromHeader();
    });
    this.setupScrollEvents();
    this.setupResizeEvents();
  },
  willDestroyElement() {
    this._super(...arguments);
    this.removeScrollEvents();
    this.removeResizeEvents();
  },

  setupScrollEvents() {
    let scrollThreshold = this.get('scrollThreshold');
    let inline = this.get('inline');
    let tableBodyWrapper = $('.table__body__wrapper', this.element);
    let table = $('.table', this.element).get(0);
    tableBodyWrapper.on('scroll', (e) => {
      let target = e.target;
      if (inline) {
        if (target.scrollTop > scrollThreshold) {
          table.classList.add('o__can-scroll-up');
        } else {
          table.classList.remove('o__can-scroll-up');
        }
      }
      $('thead', this.element).get(0).style.transform =
        `translateX(-${e.target.scrollLeft}px) translateZ(0)`;
    });
  },
  setupResizeEvents() {
    $(window).on('resize.ic-legacy-table', () => {
      debounceTask(this, 'adjustRoundedWidth', 100, false);
    });
  },
  removeScrollEvents() {
    $('.table__body__wrapper', this.element).off('scroll');
  },
  removeResizeEvents() {
    $(window).off('resize.ic-legacy-table');
  },

  adjustRoundedWidth() {
    schedule('afterRender', () => {
      if (this.isDestroying) {
        return;
      }
      this.set('roundedWidth', `${Math.floor($(this.element).width())}px`);
      this.updateWidths();
    });
  },
  getTableContentWidth() {
    if (this.isDestroying) {
      return 0;
    }
    let tableContentWidth = $('.table__body__wrapper', this.element).get(0).clientWidth;

    // Can happen during initial render. `alternateTableContentWidth` is a good
    // but usually less precise estimate, which is better than nothing here.
    if (tableContentWidth < 10) {
      tableContentWidth = this.get('alternateTableContentWidth');
    }

    return tableContentWidth;
  },
  readWidthsFromHeader() {
    let columnWidthInfo = this.get('columnWidthInfo');
    let fullColumns = this.get('fullColumns');
    let theadThs = $('thead th', this.element);

    for (let i = 0, l = fullColumns.length; i < l; i++) {
      if (columnWidthInfo[i] === undefined) {
        columnWidthInfo[i] = {};
        // scrollWidth rounds down to a whole integer, so we +1 to allow the use of minWidth
        let width = Math.floor(
          theadThs.eq(i).find('.table__cell__measure__element').get(0).scrollWidth + 1,
        );
        this.clampWidth(width, columnWidthInfo[i], fullColumns[i]);
      }
    }
    this.set('widthsReadFromHeader', true);
    this.notifyPropertyChange('columnWidthInfo');
  },

  updateWidths() {
    debounceTask(this, 'updateWidthsInAfterRender', 100, false);
  },
  updateWidthsInAfterRender() {
    schedule('afterRender', () => this.updateWidthsFunc());
  },
  updateWidthsFunc() {
    if (this.isDestroying) {
      return;
    }
    if (!this.get('widthsReadFromHeader')) {
      this.readWidthsFromHeader();
    }
    let tableContentWidth = this.getTableContentWidth();
    let runningTotalColumnWidth = 0;
    let expandableColumnIndexes = [];
    let columnWidthInfo = this.get('columnWidthInfo');
    let fullColumns = this.get('fullColumns');
    let node = $(this.element).get(0);
    let tr = node.querySelectorAll('tbody tr');
    if (tr.length === 0) {
      this.set('tableIsVisible', true);
      return;
    }
    for (let rowIndex = tr.length - 1; rowIndex >= 0; rowIndex--) {
      let cells = tr[rowIndex].querySelectorAll(
        'td>.table__cell__jammer>.table__cell__measure__element',
      );
      for (let i = 0, l = columnWidthInfo.length; i < l; i++) {
        let cell = cells[i];
        this.clampWidth(
          Math.max(columnWidthInfo[i].width, Math.floor(cell.scrollWidth + 1)),
          columnWidthInfo[i],
          fullColumns[i],
        );

        if (rowIndex === 0) {
          // Count for the last row, by which columns[i].width should be
          // the largest it needs to be
          runningTotalColumnWidth += columnWidthInfo[i].width;
          if (fullColumns[i].canExpandToFit) {
            expandableColumnIndexes.push(i);
          }

          this.clampWidth(columnWidthInfo[i].width, columnWidthInfo[i], fullColumns[i], {
            setWidth: false,
          });
        }
      }
    }

    let numberOfExpandableColumns = expandableColumnIndexes.length;

    if (numberOfExpandableColumns > 0 && runningTotalColumnWidth < tableContentWidth) {
      let remainingSpace = tableContentWidth - runningTotalColumnWidth;
      let standardExtraPixels = Math.floor(remainingSpace / numberOfExpandableColumns);
      let remainder = remainingSpace - standardExtraPixels * numberOfExpandableColumns;
      for (let i = 0; i < numberOfExpandableColumns; i++) {
        let columnIndex = expandableColumnIndexes[i];
        let widthToRender =
          columnWidthInfo[columnIndex].width + standardExtraPixels + (i === 0 ? remainder : 0);
        this.clampWidth(widthToRender, columnWidthInfo[columnIndex], fullColumns[columnIndex], {
          setWidth: false,
        });
      }
      if (this.get('scrollbarWidth') > 0) {
        schedule('afterRender', () => this.detectAvailableWidthChange(tableContentWidth));
      }
    }
    this.notifyPropertyChange('columnWidthInfo');
    if (!this.get('initialRefreshComplete')) {
      this.set('initialRefreshComplete', true);
      if (!this.get('autoHeight')) {
        schedule('afterRender', () => {
          if (this.isDestroying) {
            return;
          }
          window.dispatchEvent(new Event('resize'));
          // 250ms seems to give vertical-collection enough time to get its own work into its queues,
          // meaning that the `measure` here happens after vertical-collection's work. I've asked
          // html-next for a better API. -- @david
          runTask(
            this,
            () => {
              rafScheduler.schedule('measure', () => this.transitionToVisibleMode());
            },
            250,
          );
        });
      }
    }
  },
  detectAvailableWidthChange(tableContentWidth) {
    if (this.isDestroying) {
      return;
    }
    if (this.getTableContentWidth() !== tableContentWidth) {
      this.updateWidthsInAfterRender();
    }
  },

  clampWidth(width, columnWidthInfo, columnDefinition, opts = { setWidth: true }) {
    let clamped = width > columnDefinition.maxWidth || columnWidthInfo.clamped;
    let clampedWidth = clamped ? columnDefinition.maxWidth : width;
    let cssType = clamped ? '' : 'min-';

    columnWidthInfo.widthStyle = trustedStyle`${cssType}width: ${clampedWidth}px;`;
    columnWidthInfo.clamped = clamped;
    columnWidthInfo.widthToRender = clampedWidth;
    if (opts.setWidth) {
      columnWidthInfo.width = clampedWidth;
    }
  },
  transitionToVisibleMode() {
    if (this.isDestroying) {
      return;
    }
    this.set('tableIsVisible', true);
  },

  loadNextPageTask: task(function* () {
    yield this.get('loadNextPage')();
    this.updateWidthsInAfterRender();
  }).drop(),
  loadNextPage() {},
  toggleSort() {},

  actions: {
    loadNextPageTaskCaller() {
      if (this.get('loadNextPage') !== undefined) {
        this.get('loadNextPageTask').perform();
      }
    },
  },
});

export { LARGEST_MAX_WIDTH };
