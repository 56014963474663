import Component from '@ember/component';
import { reads } from '@ember/object/computed';
import { assert, runInDebug } from '@ember/debug';
import IcDatepickerNav from '@intercom/pulse/components/ic-datepicker-nav';

export default Component.extend({
  classNames: ['ds-new__datepicker'],
  attributeBindings: ['data-test-datepicker'],
  'data-test-datepicker': true,
  center: reads('selected'),

  init({ attrs }) {
    this._super(...arguments);
    runInDebug(() => {
      assert('component:ic-datepicker: A "@selected" attribute must be passed in', attrs.selected);
    });
  },

  get navComponent() {
    return IcDatepickerNav;
  },
});
