import $ from 'jquery';
import { schedule } from '@ember/runloop';
import ICBaseComponent from '@intercom/pulse/components/ic-base-component';
import { debounceTask, runTask } from 'ember-lifeline';

export default ICBaseComponent.extend({
  componentClasses: 'ds-new__dropdown__standard flex-auto flex flex-col min-h-0',
  filterText: '',
  filterItems(filterText) {
    this.set('filterText', filterText);
    debounceTask(this, 'processFilterItems', filterText, 60);
  },
  processFilterItems(filterText) {
    this.get('groupTree').setAllFilterTexts(filterText);

    schedule('afterRender', () => {
      if (this.isDestroying) {
        return;
      }
      runTask(
        this,
        () => {
          $('.ds-new__dropdown__group.o__main', this.element).scrollTop(0);
        },
        25,
      );
    });
  },
});
