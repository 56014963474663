import { helper as buildHelper } from '@ember/component/helper';
import { assert } from '@ember/debug';

let defaultOptions = {
  size: 16,
  solo: false,
  actAsLeftMediaObject: false, // TODO(@22a) delete this option, entirely unused as of Jan 2024
};

let allowedOptionsHash = (value, options) => {
  let hash = {};
  hash['icon'] = value;
  if (typeof options !== 'undefined') {
    ['size', 'solo', 'actAsLeftMediaObject'].forEach(function (property) {
      if (options[property] !== undefined) {
        hash[property] = options[property];
      }
    });
  }
  return hash;
};

let attachClassesToIcon = (iconNode, optionsHash) => {
  iconNode.classList.add(`o__${optionsHash['icon']}`);
  iconNode.classList.add(`o__${optionsHash['size']}`);

  if (!(optionsHash['solo'] || optionsHash['actAsLeftMediaObject'])) {
    iconNode.classList.add('o__by-text');
  }

  if (optionsHash['actAsLeftMediaObject']) {
    iconNode.classList.add('o__left-media-obj');
  }
};

let validate = function (optionsHash) {
  assert(
    'You must explicitly state which icon to use.',
    typeof optionsHash['icon'] === 'string' && optionsHash['icon'].length > 0,
  );
  assert(
    'You may only use a supported icon size.',
    [12, 16, 24, 48, 64].indexOf(optionsHash['size']) !== -1,
  );
  assert(
    'An icon cannot be both solo and actAsLeftMediaObject.',
    !(optionsHash['solo'] && optionsHash['actAsLeftMediaObject']),
  );
  assert('An icon name contains no spaces.', optionsHash['icon'].indexOf(' ') === -1);
  assert('An icon name does not start with "o__".', optionsHash['icon'].indexOf('o__') !== 0);
};

export function iconPatternHelper(params, options) {
  let iconClass = 'i';
  let value;
  if (params !== undefined) {
    value = params[0];
  }
  options = Object.assign({}, defaultOptions, allowedOptionsHash(value, options));
  validate(options);
  let icon = document.createElement('I');
  icon.classList.add(iconClass);
  attachClassesToIcon(icon, options);
  return icon;
}

export default buildHelper(iconPatternHelper);
