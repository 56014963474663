import Component from '@ember/component';

export default Component.extend({
  tagName: 'span',
  classNames: ['radio-button-group'],
  classNameBindings: ['fullWidth:flex', 'fullWidth:flex-row'],
  'data-test-radio-button-group': true,
  attributeBindings: ['data-test-radio-button-group'],
  dataTestValue: true,

  fullWidth: false,
  disabled: false,

  actions: {
    onButtonClicked(buttonValue) {
      if (this.selectedValue !== buttonValue && typeof this.onSelectionChange === 'function') {
        this.onSelectionChange(buttonValue);
      }
    },
  },
});
