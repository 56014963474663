import Service from '@ember/service';

export default Service.extend({
  scrollbarWidth: 0,
  init() {
    this._super(...arguments);
    this.checkScrollBarWidth();
  },
  checkScrollBarWidth() {
    let scrollDiv = document.createElement('div');
    scrollDiv.className = 'table__scrollbar-measure';
    document.body.appendChild(scrollDiv);

    let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    this.set('scrollbarWidth', scrollbarWidth);

    document.body.removeChild(scrollDiv);
  },
});
