import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { ensureSafeComponent } from '@embroider/util';

export default Component.extend({
  tagName: '',
  tooltipText: computed('item.tooltip.text', 'item.tooltipText', function () {
    return this.get('item.tooltip.text') || this.get('item.tooltipText');
  }),
  content: computed('tooltipText', 'item.text', function () {
    return this.get('tooltipText') || this.get('item.text');
  }),
  isProperlySelected: computed('canBeMarkedAsSelected', 'item.isSelected', function () {
    return this.get('canBeMarkedAsSelected') && this.get('item.isSelected');
  }),
  isVisiblyDisabled: computed('item.isDisabled', 'item.isLimitDisabled', function () {
    return this.get('item.isDisabled') || this.get('item.isLimitDisabled');
  }),
  isDelayed: computed('item.tooltip.isDelayed', function () {
    if (this.get('item.tooltip.isDelayed') === false) {
      return false;
    }

    return true;
  }),
  shouldRenderTooltip: computed('truncated', 'tooltipText', 'item.tooltip.content', function () {
    return (
      this.get('truncated') ||
      this.get('tooltipText') ||
      this.get('item.tooltip.component') !== undefined
    );
  }),
  attemptSelection: action(function () {
    if (this.get('onClick')) {
      this.get('onClick')();
    } else if (this.get('clickTriggersSelect')) {
      this.get('selectItem')(this.get('value'));
    }
  }),
  attemptFocus: action(function () {
    if (this.get('onFocus')) {
      this.get('onFocus')();
    }
  }),
  checkForTruncation: action(function (element) {
    if (!this.isDestroying) {
      this.checkForTruncationAfterDelay.perform(element, 10);
    }
  }),
  // We measure the text containing element after an arbitrarily long period
  // of time so that the browser has a chance to apply the max-width style on
  // the ic-dropdown element and cause truncation to occur
  checkForTruncationAfterDelay: task(function* (element, delay) {
    yield timeout(delay);
    if (!this.isDestroying) {
      if (element) {
        this.set('truncated', element.offsetWidth < element.scrollWidth);
      } else {
        this.set('truncated', false);
      }
    }
  }),

  get itemTooltipComponentClass() {
    return typeof this.item.tooltip.component === 'string'
      ? ensureSafeComponent(this.item.tooltip.component, this)
      : this.item.tooltip.component;
  },
});
