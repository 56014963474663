import Modifier from 'ember-modifier';
import { createPopper } from '@popperjs/core';
import { action } from '@ember/object';
import {
  DEFAULT_OPTIONS,
  DIRECTIONS,
  INVERSE_DIRECTIONS,
  elementIsChildOfAny,
  elementIsChildOfPopover,
} from '@intercom/pulse/lib/popover-utils';
import { guidFor } from '@ember/object/internals';
import { assert, runInDebug } from '@ember/debug';
import trustedStyle from '@intercom/pulse/lib/trusted-style';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { timeout } from 'ember-concurrency';
export default class TooltipModifier extends Modifier {
  contentElement = null;
  popperInstance = null;
  id = null;
  isVisible = false;
  hovered = false;

  didInstall() {
    this.id = guidFor(this);
    this.element.setAttribute('data-popover-opener', this.id);
    this.element.setAttribute('data-test-popover-container', this.id);
    this.addEventListeners();
  }

  didUpdateArguments() {
    if (this.isDisabled && this.isVisible) {
      this.hideTooltip();
    } else if (this.popperInstance) {
      this.popperInstance.update();
    }
  }

  willRemove() {
    this.removeEventListeners();
    this.hideTooltip();
    this.element.removeAttribute('data-popover-opener');
    this.element.removeAttribute('data-test-popover-container');
  }

  addEventListeners() {
    this.element.addEventListener('mouseenter', this.handleMouseEnter);
    this.element.addEventListener('mouseleave', this.handleMouseLeave);
  }

  removeEventListeners() {
    this.element.removeEventListener('mouseenter', this.handleMouseEnter);
    this.element.removeEventListener('mouseleave', this.handleMouseLeave);
  }

  @action
  async handleMouseEnter() {
    this.hovered = true;
    if (!this.isDisabled) {
      if (this.args.named.isDelayed) {
        await timeout(this.args.named.delayLength || 200);
      }
      if (this.hovered) {
        this.showTooltip();
      }
    }
  }

  @action
  handleMouseLeave() {
    this.hovered = false;
    if (this.args.named.isInteractive) {
      if (!elementIsChildOfAny(event.relatedTarget, [`#${this.contentElementId}`])) {
        this.hideTooltip();
      } else {
        // The cursor has moved inside of our popper content so we do
        // nothing now and wait for the mouseleave event of our popper
        // child to be fired and handled by handleTooltipMouseLeave below
      }
    } else {
      // A mouseleave fired on the opener of a non-interactive Popover should
      // result in it closing
      this.hideTooltip();
    }
  }

  @action
  showTooltip() {
    if (this.isVisible) {
      return;
    }
    this.setupTooltipHTML();
    this.popperInstance = this.setupPopper(this.element, this.contentElement);
    this.contentElement.addEventListener('mouseleave', this.handleTooltipMouseLeave);
    this.contentElement.querySelector('[data-popover-content-container]').dataset.animationSettled =
      true;
    this.isVisible = true;
  }

  hideTooltip() {
    if (!window.DEBUG_preventPopoverRemoval) {
      this.popperInstance?.destroy();
      this.contentElement?.removeEventListener('mouseleave', this.handleTooltipMouseLeave);
      // We can't use `.remove()` here because IE11 doesn't support it
      this.contentElement?.parentElement.removeChild(this.contentElement);
      this.popperInstance = null;
      this.contentElement = null;
      this.isVisible = false;
    }
  }

  @action
  handleTooltipMouseLeave(event) {
    if (
      !elementIsChildOfAny(event.relatedTarget, [
        this.openerElementSelector,
        `#${this.contentElementId}`,
      ])
    ) {
      // The cursor left our Popover content so we should hide
      this.hideTooltip();
    }
  }

  setupTooltipHTML() {
    let div = document.createElement('div');
    div.id = this.contentElementId;
    div.setAttribute('data-popover-content', this.contentElementId);
    div.setAttribute('data-tooltip-modifier', true);
    if (elementIsChildOfPopover(this.element)) {
      this.element.parentElement.appendChild(div);
    } else {
      document.body.appendChild(div);
    }
    div.innerHTML = sanitizeHtml(`
      <div
        class="popover__content-container a__tooltip ${this.darkClass}"
        style="${this.tooltipStyle}"
        data-popover-content-container
      >
        <div data-popper-arrow></div>
        <div class="popover__content tooltip ${this.darkClass}" data-tooltip-content></div>
      </div>
    `);
    div.querySelector('[data-tooltip-content]').textContent = this.args.named.content;
    this.contentElement = div;
  }

  setupPopper(element, contentElement) {
    return createPopper(element, contentElement, {
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: this.fallbackPlacements,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
        {
          name: 'computePlacement',
          enabled: true,
          phase: 'afterWrite',
        },
        {
          name: 'preventOverflow',
          options: {
            mainAxis: true,
            padding: 4,
          },
        },
        {
          name: 'arrow',
          options: {
            padding: 6,
          },
        },
      ],
      placement: this.placement,
    });
  }

  get isDisabled() {
    return (
      Boolean(this.args.named.isDisabled) ||
      (this.args.named.showOnlyWhenTruncated && !this.isTruncated)
    );
  }

  get isTruncated() {
    let truncationDetectionElement =
      this.element.querySelector('[data-tooltip-truncation-marker]') || this.element;
    let isTruncatedHorizontally =
      truncationDetectionElement.offsetWidth < truncationDetectionElement.scrollWidth;
    let isTruncatedVertically =
      truncationDetectionElement.clientHeight < truncationDetectionElement.scrollHeight;
    return isTruncatedHorizontally || isTruncatedVertically;
  }

  get placement() {
    runInDebug(() => {
      assert(
        `{{tooltip}}: the provided \`placement\` argument "${
          this.args.named.placement
        }" is not valid. Valid values include "${DIRECTIONS.join(', ')}"`,
        this.args.named.placement === undefined || DIRECTIONS.includes(this.args.named.placement),
      );
    });
    return this.args.named.placement || 'top';
  }

  get fallbackPlacements() {
    return [INVERSE_DIRECTIONS[this.placement]];
  }

  get tooltipStyle() {
    return this.animationStyle;
  }

  get animationStyle() {
    let {
      ANIMATION: { DURATION, TIMING_FUNCTION },
    } = DEFAULT_OPTIONS;
    return trustedStyle`transition-duration: ${DURATION}ms;
        transition-timing-function: ${TIMING_FUNCTION};`;
  }

  get darkClass() {
    return this.args.named.isDark ? 'o__dark' : '';
  }

  get openerElementSelector() {
    return `[data-popover-opener="${this.id}"]`;
  }

  get contentElementId() {
    return `popper-${this.id}`;
  }
}
