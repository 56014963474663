import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { getAutoplayUrlForWistiaId } from '@intercom/pulse/lib/wistia-url';

export default class VideoModal extends Component {
  @service intercomEventService;
  videoURL = getAutoplayUrlForWistiaId(this.args.wistiaId);

  @action
  onModalClose() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      object: 'video',
      video_title: this.args.title,
      video_id: this.args.wistiaId,
    });
    this.args.onModalClose();
  }
}
