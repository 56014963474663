import { computed } from '@ember/object';
import { isNone } from '@ember/utils';

export default function hashWithoutNulls(...params) {
  params.push(function () {
    let values = this.getProperties(params.slice(0, -1));
    for (let property in values) {
      if (isNone(values[property])) {
        delete values[property];
      }
    }
    return values;
  });
  return computed.apply(this, params);
}
