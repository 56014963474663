import Component from '@glimmer/component';
import { action } from '@ember/object';
import trustedStyle from '@intercom/pulse/lib/trusted-style';
import keycodes from '@intercom/pulse/lib/keynames';

export default class extends Component {
  get widthStyle() {
    let column = this.args.column;
    return trustedStyle`width: ${column.width}; min-width: ${column.minWidth}; max-width: ${column.maxWidth};`;
  }

  get isActiveSort() {
    return this.args.column.valuePath === this.args.sortState.valuePath;
  }

  @action
  onClick(type, event) {
    if (this.args.column.isSortable && !this.args.tableIsLoading) {
      this.args.onClick(this.args.column.valuePath, 'sort');
    }
    if (type === 'select' && !this.args.tableIsLoading) {
      this.args.onClick(this.args.column.valuePath, 'select');
    }
    // remove focus when a column is clicked so that we don't persist
    // the hover/focus visual style
    event.target.closest('th').blur();
  }

  @action
  onKeyDown(type, event) {
    if (this.args.column.isSortable && !this.args.tableIsLoading) {
      if (event.keyCode === keycodes.enter) {
        // allow ENTER keypress to toggle the column sort direction
        this.args.onClick(this.args.column.valuePath, 'sort');
      } else if (event.keyCode === keycodes.space) {
        // allow SPACE keypress to toggle the column sort direction
        this.args.onClick(this.args.column.valuePath, 'sort');
        // prevent page scroll when we sort a column using the space key
        return false;
      }
    }
  }
}
