import { computed } from '@ember/object';

const slice = Array.prototype.slice;

export default function hash() {
  let params = slice.call(arguments);
  params.push(function () {
    return this.getProperties(params.slice(0, -1));
  });
  return computed.apply(this, params);
}
