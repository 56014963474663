import { computed } from '@ember/object';
import ICBaseComponent from './ic-base-component';

export default ICBaseComponent.extend({
  groupIndex: computed('item.groupIndex', function () {
    return this.get('item.groupIndex') || 0;
  }),
  text: computed('item.text', 'textPositionalParameter', function () {
    return this.get('item.text') || this.get('textPositionalParameter') || '';
  }),
  componentClasses: computed('groupIndex', function () {
    return `ds-new__dropdown__block__heading o__index-${this.get('groupIndex')}`;
  }),
  stamp: computed('item.stamp', function () {
    return this.get('item.stamp');
  }),
}).reopenClass({
  positionalParams: ['textPositionalParameter'],
});
