import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class IcDatePickerNav extends Component {
  @action
  decrementMonth(e) {
    this.args.calendar.actions.moveCenter(-1, 'month');
    e.target.closest('button').blur();
  }

  @action
  incrementMonth(e) {
    this.args.calendar.actions.moveCenter(1, 'month');
    e.target.closest('button').blur();
  }
}
