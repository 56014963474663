import { runInDebug } from '@ember/debug';
import ICSelect from './ic-select';

export default ICSelect.extend({
  layoutName: 'components/ic-select',
  isMultiSelect: true,

  validate() {
    this._super(...arguments);

    runInDebug(() => {
      this.checkForIncorrectUse(
        !this.get('label'),
        'The ic-multiselect requires a label.',
        'label',
      );

      this.checkForIncorrectUse(
        this.get('limit') === 0,
        'Please set disabled=true over limit=0',
        'limit',
      );
    });
  },
});
