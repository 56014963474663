import { computed } from '@ember/object';
import ICBaseComponent from './ic-base-component';
import { inject as service } from '@ember/service';

export default ICBaseComponent.extend({
  intl: service(),

  componentClasses: 'if-this-then-that',
  displayString: computed('type', function () {
    switch (this.get('type')) {
      case 'and':
        return this.intl.t('pulse.ic-if-this-then-that.and');

      case 'if':
        return this.intl.t('pulse.ic-if-this-then-that.if');

      case 'or':
        return this.intl.t('pulse.ic-if-this-then-that.or');

      case 'then':
        return this.intl.t('pulse.ic-if-this-then-that.then');

      case 'always':
        return this.intl.t('pulse.ic-if-this-then-that.always');

      case 'else':
        return this.intl.t('pulse.ic-if-this-then-that.else');

      default:
    }
    // return TYPE_TO_DISPLAY_STRING_MAP[this.get('type')];
  }),
});
