import { getOwner } from '@ember/application';
import { assert, warn } from '@ember/debug';
import { assign } from '@ember/polyfills';
import Service, { inject as service } from '@ember/service';
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';
import { underscore } from '@ember/string';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';

// DOCS AT https://go.inter.com/ember-analytics-events

const SERVICE_KEY = 'service:intercom-event-service -';
const LOCALSTORAGE_ANALYTICS_SEQNO_KEY = 'analytics_seqno';
const LOCALSTORAGE_ANALYTICS_CLIENT_ID_KEY = 'analytics_client_id';

export default Service.extend({
  cookies: service(),

  trackEvent(name, metadata, useBeaconApi) {
    if (UserAgentDetector.isGhostInspector()) {
      return;
    }

    metadata = metadata || {};
    if (typeof Intercom !== 'undefined') {
      let app = getOwner(this).lookup('route:apps/app')?.app;

      if (app) {
        metadata['app_id_code'] = app.get('id');
        metadata['admin_id'] = app.get('currentAdmin.id');
      }
      Intercom('trackEvent', name, metadata, useBeaconApi);
    }
  },

  trackAnalyticsEvent(metadata, useBeaconApi) {
    // copy the metadata so that we can mutate it safely (this matters when invoked from a hbs helper)
    metadata = Object.assign({}, metadata);

    let environmentData = this._getEnvironmentData();
    let eventData = this._getAnalyticsDataFromRouteHierarchy(metadata);

    let modelsData = this._getModelsAnalyticsData(metadata.models);
    delete metadata.models;

    let objectData;
    if (
      metadata.object &&
      typeof metadata.object !== 'string' &&
      typeof metadata.object !== 'number'
    ) {
      objectData = this._getObjectAnalyticsData(metadata.object);
      delete metadata.object;
    }

    eventData = assign(environmentData, eventData, metadata, modelsData, objectData);

    assert(
      `${SERVICE_KEY} argument of \`trackAnalyticsEvent\` should have an \`action\` property`,
      eventData.action,
    );
    assert(
      `${SERVICE_KEY} argument of \`trackAnalyticsEvent\` should have an \`object\` property`,
      eventData.object,
    );

    warn(
      this._getNameFormatWarning('action', eventData.action),
      underscore(eventData.action) === eventData.action,
      { id: 'pulse.trackAnalyticsEvent.naming' },
    );
    if (typeof eventData.object !== 'number') {
      warn(
        this._getNameFormatWarning('object', eventData.object),
        underscore(eventData.object) === eventData.object,
        {
          id: 'pulse.trackAnalyticsEvent.naming',
        },
      );
    }

    warn(
      this._getObjectNounMessage(eventData.object),
      typeof eventData.object !== 'number' && !/^\d+$/g.test(eventData.object),
      {
        id: 'pulse.trackAnalyticsEvent.object-value',
      },
    );

    this.trackEvent('analytics-event', eventData, useBeaconApi);
    this._incrementAnalyticsSeqNo();
  },

  _getNameFormatWarning(key, value) {
    return `${SERVICE_KEY} \`trackAnalyticsEvent\` requires the \`${key}\` value to be snake_case.
You should change \`${value}\` to be \`${underscore(
      value,
    )}\`. See https://go.inter.com/ember-analytics-events for more details.`;
  },

  _getObjectNounMessage(value) {
    return `${SERVICE_KEY} trackAnalyticsEvent \`object\` has a value of \`${value}\`. \`object\` should be a noun and not an id. See https://go.inter.com/ember-analytics-events for more details.`;
  },

  _getEnvironmentData() {
    let environmentData = {
      environment_current_url: window.location.href,
      environment_current_route: this._getRouter().get('currentRouteName'),
      environment_event_type: 'embercom-track-analytics-event',
      environment_gtm_id: this.cookies.read('gtm_id'),
      environment_tab_time_elapsed: window.performance.now(),
      environment_client_time: moment().toISOString(true),
    };

    // Used to track e2e loss of analytics events
    environmentData.analytics_client_id = this._getAnalyticsClientId();
    environmentData.analytics_seqno = this._getAnalyticsSeqNo();

    try {
      environmentData.environment_tab_identifier =
        window.sessionStorage.getItem('embercom_tab_identifier');
    } catch (e) {
      // NOOP
    }

    return environmentData;
  },

  _getAnalyticsClientId() {
    let clientId = localStorage.getItem(LOCALSTORAGE_ANALYTICS_CLIENT_ID_KEY);
    if (!clientId) {
      clientId = uuidv4();
      localStorage.setItem(LOCALSTORAGE_ANALYTICS_CLIENT_ID_KEY, clientId);
      localStorage.setItem(LOCALSTORAGE_ANALYTICS_SEQNO_KEY, 0);
    }
    return clientId;
  },

  _getAnalyticsSeqNo() {
    let seqNo = localStorage.getItem(LOCALSTORAGE_ANALYTICS_SEQNO_KEY) || 0;
    return new Number(seqNo);
  },

  _incrementAnalyticsSeqNo() {
    let newSeqNo = this._getAnalyticsSeqNo() + 1;
    window.localStorage.setItem(LOCALSTORAGE_ANALYTICS_SEQNO_KEY, newSeqNo.toString());
  },

  _getModelsAnalyticsData(models) {
    if (models && models.length > 0) {
      let modelData = [];

      models.forEach((model) => {
        let analyticsData = this._getObjectAnalyticsData(model);
        analyticsData = Object.assign({}, analyticsData);
        delete analyticsData.object;
        modelData.push(analyticsData);
      });

      return assign.apply(this, modelData);
    }
  },

  _getObjectAnalyticsData(object) {
    let data = object && object.get('analyticsData');

    if (data) {
      assert(
        `${SERVICE_KEY} An "analyticsData" computed property must include an "object" property which is a string`,
        typeof data.object === 'string',
      );
    } else {
      assert(
        `${SERVICE_KEY} An object provided to \`trackAnalyticsEvent\` must define an \`analyticsData\` computed property`,
      );
    }

    return data;
  },

  _getAnalyticsDataFromRouteHierarchy({ place, section }) {
    let routeInfos = this._getCurrentRouteInfos();

    let routerAnalyticsData = {};
    routeInfos.forEach((routeInfo) => {
      let analyticsData;
      analyticsData = routeInfo._route.analytics;

      if (analyticsData) {
        routerAnalyticsData = assign(routerAnalyticsData, analyticsData);
      }
    });

    warn(
      `${SERVICE_KEY} You must specify a \`section\` in route \`analytics\` data or supply it as metadata`,
      routerAnalyticsData.section || section,
      { id: 'embercom.trackAnalyticsEvent.routeDataMissing' },
    );
    warn(
      `${SERVICE_KEY} You must specify a \`place\` in route \`analytics\` data or supply it as metadata`,
      routerAnalyticsData.place || place,
      { id: 'embercom.trackAnalyticsEvent.routeDataMissing' },
    );

    routerAnalyticsData.section = routerAnalyticsData.section || 'unknown';
    routerAnalyticsData.place = routerAnalyticsData.place || 'unknown';

    warn(
      `${SERVICE_KEY} The \`place\` value must be a string. See https://go.inter.com/ember-analytics-events for more details.`,
      typeof routerAnalyticsData.place === 'string',
      { id: 'pulse.trackAnalyticsEvent.format' },
    );

    return routerAnalyticsData;
  },

  _getCurrentRouteInfos() {
    let router = this._getRouter();
    let routerMicrolib = router._routerMicrolib;
    return routerMicrolib.currentRouteInfos || routerMicrolib.currentHandlerInfos;
  },

  _getRouter() {
    return getOwner(this).lookup('router:main');
  },
});
