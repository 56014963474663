import { getOwner } from '@ember/application';
import { get } from '@ember/object';
import Helper from '@ember/component/helper';

export default class ServiceHelper extends Helper {
  compute([servicePath]) {
    let owner = getOwner(this);

    let parts = servicePath.split('.');
    let serviceName = parts.shift();
    let service = owner.lookup(`service:${serviceName}`);

    if (parts.length > 0) {
      let path = parts.join('.');
      return get(service, path);
    } else {
      return service;
    }
  }
}
