import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { createPopper } from '@popperjs/core';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import { assert, runInDebug } from '@ember/debug';
import {
  BROWSER_SUPPORTS_TOUCH,
  DEFAULT_OPTIONS,
  ROOT_PARENT_SELECTOR,
  INVERSE_DIRECTIONS,
  elementIsAttachedToTheDOM,
  elementIsChildOfAny,
  elementIsChildOfPopover,
} from '@intercom/pulse/lib/popover-utils';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default class Popover extends Component {
  @tracked animationSettled = false;
  @tracked isVisible = false;
  @tracked alternateRenderContext;
  @tracked id;
  @tracked placementDirection;
  @tracked popperInstance;
  resizeObserver;
  animationFrameId;

  constructor() {
    super(...arguments);
    this.id = guidFor(this);
  }
  willDestroy() {
    if (this.isVisible) {
      this.hideAsScheduled();
    }

    this.destroyPopperInstance();
    this.alternateRenderContext = null;

    super.willDestroy(...arguments);
  }

  get virtualElement() {
    if (!this.args.element) {
      return undefined;
    }
    return {
      getBoundingClientRect: () => this.args.element?.getBoundingClientRect?.(),
      closest: () => false,
    };
  }

  // In these 4 getters we keep a reference to the opener and overlay
  // elements so that the popper instance can interact with them
  get openerElementId() {
    return `reference-${this.id}`;
  }
  get openerElement() {
    return this.virtualElement || document.getElementById(this.openerElementId);
  }
  get contentElementId() {
    return `popper-${this.id}`;
  }
  get contentElement() {
    return document.getElementById(this.contentElementId);
  }

  // The following getters are local fields derived from arguments provided to
  // the Popover component - they compute styles, set defaults, and generate
  // config which will be passed to popper.
  get popoverContentStyle() {
    let computedStyles = [this.offsetDistanceStyle, this.transitionStyle].filter(Boolean);
    if (computedStyles) {
      return sanitizeHtml(computedStyles.join('; '));
    }
    return null;
  }
  get transitionStyle() {
    if (this.shouldAnimate) {
      return `transition-duration: ${this.animation.duration}ms;
        transition-timing-function: ${this.animation.timingFunction}`;
    }
    return null;
  }
  get offsetDistanceStyle() {
    let offsetDistance =
      typeof this.args.offsetDistance === 'number'
        ? this.args.offsetDistance
        : DEFAULT_OPTIONS.OFFSET_DISTANCE;
    if (offsetDistance !== 0) {
      let inversePlacementDirection = INVERSE_DIRECTIONS[this.placementDirection];
      return `margin-${inversePlacementDirection}: ${offsetDistance}px`;
    }
    return null;
  }
  get modifiers() {
    let modifiers = [];

    modifiers.push({
      name: 'flip',
      options: {
        fallbackPlacements: this.fallbackPlacements,
      },
    });
    modifiers.push({
      name: 'offset',
      options: {
        offset: [this.args.offsetSkid || DEFAULT_OPTIONS.OFFSET_SKID, 0],
      },
    });
    modifiers.push({
      name: 'computePlacement',
      enabled: true,
      phase: 'afterWrite',
      fn: ({ state }) => {
        if (this.isVisible) {
          this.setPlacementDirection(state.placement);
        }
      },
    });
    modifiers.push({
      name: 'preventOverflow',
      options: {
        mainAxis: this.preventMainAxisOverflow,
        boundary: DEFAULT_OPTIONS.PREVENT_OVERFLOW.BOUNDARY,
        padding: DEFAULT_OPTIONS.PREVENT_OVERFLOW.PADDING,
      },
    });
    if (this.args.arrow) {
      modifiers.push({
        name: 'arrow',
        options: {
          padding: this.args.arrowOverflowPadding || DEFAULT_OPTIONS.ARROW_OVERFLOW_PADDING,
        },
      });
    }
    if (this.args.modifiers?.length) {
      return modifiers.concat(this.args.modifiers);
    }
    return modifiers;
  }
  get showOn() {
    runInDebug(() => {
      assert(
        `<Popover>: the provided @showOn argument (${JSON.stringify(
          this.args.showOn,
        )}) is not a list, ensure you specify it as @showOn={{array 'click'}} instead of @showOn="click"`,
        this.args.showOn === undefined || Array.isArray(this.args.showOn),
      );
    });
    return Array.isArray(this.args.showOn) ? this.args.showOn : DEFAULT_OPTIONS.SHOW_ON;
  }
  get hideOn() {
    runInDebug(() => {
      assert(
        `<Popover>: the provided @hideOn argument (${JSON.stringify(
          this.args.hideOn,
        )}) is not a list, ensure you specify it as @hideOn={{array 'clickout'}} instead of @hideOn="clickout"`,
        this.args.hideOn === undefined || Array.isArray(this.args.hideOn),
      );
    });
    return Array.isArray(this.args.hideOn) ? this.args.hideOn : DEFAULT_OPTIONS.HIDE_ON;
  }
  get placement() {
    return this.args.placement ? this.args.placement : DEFAULT_OPTIONS.PLACEMENT;
  }
  get fallbackPlacements() {
    runInDebug(() => {
      assert(
        `<Popover>: the provided @fallbackPlacements argument (${JSON.stringify(
          this.args.fallbackPlacements,
        )}) is not a list, ensure you specify it as @fallbackPlacements={{array 'top' 'left-start' 'right-end'}} instead of @fallbackPlacements="top"`,
        this.args.fallbackPlacements === undefined || Array.isArray(this.args.fallbackPlacements),
      );
    });
    return Array.isArray(this.args.fallbackPlacements)
      ? this.args.fallbackPlacements
      : DEFAULT_OPTIONS.FALLBACK_PLACEMENTS;
  }
  get interactive() {
    return this.args.isInteractive === undefined
      ? DEFAULT_OPTIONS.INTERACTIVE
      : this.args.isInteractive;
  }
  get preventMainAxisOverflow() {
    return this.args.preventOverflow === undefined
      ? DEFAULT_OPTIONS.PREVENT_OVERFLOW.MAIN_AXIS
      : Boolean(this.args.preventOverflow);
  }
  get animation() {
    if (this.shouldAnimate) {
      return {
        class: this.args.animation.class,
        duration:
          typeof this.args.animation.duration === 'number'
            ? this.args.animation.duration
            : DEFAULT_OPTIONS.ANIMATION.DURATION,
        timingFunction:
          this.args.animation.timingFunction || DEFAULT_OPTIONS.ANIMATION.TIMING_FUNCTION,
      };
    }
    return null;
  }
  get showDelay() {
    return typeof this.args.showDelay === 'number'
      ? this.args.showDelay
      : DEFAULT_OPTIONS.DELAY.SHOW;
  }
  get hideDelay() {
    let hideDelay =
      typeof this.args.hideDelay === 'number' ? this.args.hideDelay : DEFAULT_OPTIONS.DELAY.HIDE;
    if (this.shouldAnimate) {
      // If an animation duration is specified, always give it at least enough
      // time to complete before we remove the element from the DOM
      return Math.max(hideDelay, this.animation.duration);
    } else {
      return hideDelay;
    }
  }
  get shouldAnimate() {
    return Boolean(this.args.animation?.class);
  }
  get touchFallbackEnabled() {
    let stateModelInvolvesMouseEvent =
      this.showOn.includes('mouseenter') || this.hideOn.includes('mouseleave');
    return BROWSER_SUPPORTS_TOUCH && stateModelInvolvesMouseEvent;
  }

  @action
  setPlacementDirection(placement) {
    this.placementDirection = placement.split('-')[0];
  }

  // The following 7 actions/tasks implement the mechanism for programatically
  // showing or hiding a Popover. The encapsulate concerns such as animating
  // entrance / exit such that the elements can remain in the DOM as long as is
  // necessary.
  @action
  show() {
    if (!this.args.isDisabled) {
      this.hideAfterDelay.cancelAll();
      // Only delay setting isVisible if a delay period has been specified
      // and we're not already visible (this will be the case when we're
      // in the middle of the hide animation)
      if (this.showDelay > 0 && !this.isVisible) {
        this.showAfterDelay.perform(this.showDelay);
      } else {
        this.showAsScheduled();
      }
    }
  }

  @task({ drop: true })
  *showAfterDelay(delay) {
    yield timeout(delay);
    this.showAsScheduled();
  }

  // This action acts as an observer, allowing the consumer to pass an element
  // reference and control the state model of the Popover themselves
  @action
  updateVirtualElement() {
    if (this.virtualElement) {
      this.show();
      if (this.popperInstance) {
        this.popperInstance.update();
      }
    } else {
      this.hide();
    }
  }

  @action
  showAsScheduled() {
    if (typeof this.args.onShow === 'function' && !this.isVisible) {
      this.args.onShow();
    }
    this.isVisible = true;
    // This is a bit of a hack to add the "animating in" class AFTER the
    // element has been added to the DOM so that animations begin correctly
    // instead of immediately rendering in their post-transition state
    if (this.shouldAnimate) {
      this.triggerPopoverEntranceAnimationAfterDelay.perform(1);
    }
    if (this.hideOn.includes('clickout')) {
      this.ensureDocumentClickHandlerIsSetUp();
    }
  }

  @task({ drop: true })
  *triggerPopoverEntranceAnimationAfterDelay(delay) {
    yield timeout(delay);
    this.animationSettled = true;
  }

  @action
  hide() {
    // This conditional allows engineers to set
    //   `window.DEBUG_preventPopoverRemoval = true`
    // to more easily debug popover content locally
    if (!window.DEBUG_preventPopoverRemoval) {
      this.showAfterDelay.cancelAll();
      if (this.hideDelay) {
        this.hideAfterDelay.perform(this.hideDelay);
      } else {
        this.hideAsScheduled();
      }
    }
  }

  @task({ drop: true })
  *hideAfterDelay(delay) {
    this.animationSettled = false;
    yield timeout(delay);
    this.hideAsScheduled();
  }

  @action
  hideAsScheduled() {
    this.removeDocumentClickHandler();
    this.isVisible = false;

    if (!this.isDestroying && typeof this.args.onHide === 'function') {
      this.args.onHide();
    }
  }

  // The following methods set up and tear down the resize observer used to
  // ensure our Popper instance recalculates its positioning relative to the
  // reference element when our Popover opener or content elements change size
  addResizeObservers() {
    if (
      (this.args.repositionOnOpenerResize || this.args.repositionOnContentResize) &&
      window.ResizeObserver !== undefined &&
      !this.resizeObserver
    ) {
      let ro = new ResizeObserver((_entries) => {
        this.popperInstance.update();
      });
      // rAF is required here because browsers don't currently implement the
      // ResizeObserver spec correctly: https://github.com/hshoff/vx/pull/335
      // https://github.com/WICG/ResizeObserver/issues/38
      this.animationFrameId = window.requestAnimationFrame(() => {
        if (this.args.repositionOnOpenerResize && this.openerElement) {
          ro.observe(this.openerElement);
        }
        if (this.args.repositionOnContentResize && this.contentElement) {
          ro.observe(this.contentElement);
        }
      });
      this.resizeObserver = ro;
    }
  }

  removeResizeObserver() {
    if (this.resizeObserver) {
      window.cancelAnimationFrame(this.animationFrameId);
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  // The following document click handler setup actions and tasks allow us
  // to support the 'clickout' hideOn state model - we would prefer to do this
  // using the on-document hbs helper but it doesn't properly clean up after
  // itself when the content component is removed from the page so we do it
  // here in the top level component JS
  @action
  addDocumentClickHandler() {
    // We add this event listener after an arbitrarily long period of time
    // so that any clicks that cause ember router navigation have had time
    // to bubble correctly so that we don't listen to them immediately after
    // rendering an 'initiallyOpen' Popover
    this.addDocumentClickHandlerAfterDelay.perform(10);
  }

  @task({ drop: true })
  *addDocumentClickHandlerAfterDelay(delay) {
    yield timeout(delay);
    this.documentClickHandlerIsRegistered = true;
    document.addEventListener('click', this.handleDocumentClick);
  }

  @action
  removeDocumentClickHandler() {
    this.addDocumentClickHandlerAfterDelay.cancelAll();
    this.documentClickHandlerIsRegistered = false;
    document.removeEventListener('click', this.handleDocumentClick);
  }

  @action
  ensureDocumentClickHandlerIsSetUp() {
    let hideOn = this.hideOn;
    if (hideOn.includes('clickout') && !this.documentClickHandlerIsRegistered) {
      this.addDocumentClickHandler();
    } else if (!hideOn.includes('clickout') && this.documentClickHandlerIsRegistered) {
      this.removeDocumentClickHandler();
    }
  }

  // The following `handleX` actions allow us to implement the "state model"
  // for the popover, providing the foundation for deciding whether a popover
  // should open or closed based on user interaction
  @action
  handleDocumentClick(event) {
    if (this.isVisible) {
      // Only close the overlay if the click wasn't inside our opener
      // or overlay content and the target is still in the DOM (more details
      // on this guard are available alongside the elementIsAttachedToTheDOM
      // function definition)
      if (
        !elementIsChildOfAny(event.target, [
          `#${this.openerElementId}`,
          `#${this.contentElementId}`,
        ]) &&
        elementIsAttachedToTheDOM(event.target)
      ) {
        this.hide();
      }
    }
  }

  @action
  handleClick() {
    if (this.isVisible) {
      if (this.hideOn.includes('click') || this.touchFallbackEnabled) {
        this.hide();
      }
    } else if (this.showOn.includes('click') || this.touchFallbackEnabled) {
      this.show();
    }
  }

  @action
  handleMouseEnter(_event) {
    if (this.showOn.includes('mouseenter')) {
      this.show();
    }
  }

  @action
  handleMouseLeave(event) {
    if (this.hideOn.includes('mouseleave')) {
      if (this.interactive) {
        if (!elementIsChildOfAny(event.relatedTarget, [`#${this.contentElementId}`])) {
          this.hide();
        } else {
          // The cursor has moved inside of our popper content so we do
          // nothing now and wait for the mouseleave event of our popper
          // child to be fired and handled by handlePopperMouseLeave below
        }
      } else {
        // A mouseleave fired on the opener of a non-interactive Popover should
        // result in it closing
        this.hide();
      }
    }
  }

  @action
  handlePopperMouseLeave(event) {
    if (this.hideOn.includes('mouseleave')) {
      if (
        !elementIsChildOfAny(event.relatedTarget, [
          `#${this.openerElementId}`,
          `#${this.contentElementId}`,
        ])
      ) {
        // The cursor left our Popover content so we should hide
        this.hide();
      }
    }
  }

  // This action is used by the content's did-insert modifier to cancel any
  // in-flight hideAfterDelay tasks that can have been caused by the
  // user moving their cursor outside the bounds of the opener and the content
  // elements on their path to interact with the Popover content
  @action
  handlePopperMouseEnter() {
    if (this.interactive) {
      this.show();
    }
  }

  // This action is used by the opener's did-insert modifier to decide where
  // the Popover content element should render. We do this ahead of time so
  // that it is available when we eventually interact with the opener.
  @action
  setupRenderingContext(element) {
    if (
      !this.args.alwaysRenderInPlace &&
      (this.args.neverRenderInPlace || !this.interactive || !elementIsChildOfPopover(element))
    ) {
      this.alternateRenderContext = document.querySelector(ROOT_PARENT_SELECTOR);
    }
    if (this.args.initiallyOpen) {
      this.showAsScheduled();
    }
  }

  // These three actions allow us to interact with the popper instance:
  // https://popper.js.org/docs/v2/constructors/#instance
  @action
  createPopper() {
    this.destroyPopperInstance();
    this.popperInstance = createPopper(this.openerElement, this.contentElement, {
      modifiers: this.modifiers,
      placement: this.placement,
      strategy: this.args.strategy,
    });
    this.addResizeObservers();
  }

  @action
  updatePopper() {
    if (this.args.isDisabled) {
      this.hideAsScheduled();
    } else if (this.popperInstance) {
      this.popperInstance.setOptions({
        modifiers: this.modifiers,
        placement: this.placement,
        strategy: this.args.strategy,
      });
    } else {
      // The popper hasn't been created yet (probably because params changed
      // before the popover was interacted with, so we can safely do nothing)
    }
  }

  @action
  destroyPopperInstance() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
    this.removeResizeObserver();
  }
}
