import { computed, get } from '@ember/object';

export default function rejectByProperty(dependentKey, propertyKey, valueKey) {
  return computed(`${dependentKey}.@each.${propertyKey}`, valueKey, function () {
    let collection = get(this, dependentKey);
    if (!collection || !collection.rejectBy) {
      return [];
    }
    return collection.rejectBy(propertyKey, this.get(valueKey));
  });
}
