import { computed } from '@ember/object';
import ICBaseComponent from '@intercom/pulse/components/ic-base-component';

export default ICBaseComponent.extend({
  tagName: 'td',
  componentClasses: 'table__cell',
  property: computed('row', 'column.propertyName', function () {
    let propertyName = this.get('column.propertyName');
    let row = this.get('row');
    if (propertyName === undefined) {
      return row;
    } else {
      return row[propertyName];
    }
  }),
  tableAction: computed('tableActions', 'column.tableActionName', function () {
    return this.get('tableActions')[this.get('column.tableActionName')];
  }),
  buttonOnClick() {
    let property = this.get('property');
    let tableAction = this.get('tableAction');
    return tableAction(property);
  },
});
