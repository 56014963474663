import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { calculateMaxHeight } from '@intercom/pulse/lib/dropdown-utils';

export default class IcDropdownAndOpenerPair extends Component {
  @tracked openerWidth;
  @tracked maxOverlayHeight;

  @action
  measureOpener(data) {
    if (!this.isDestroying) {
      this.openerWidth = data.state.elements.reference.offsetWidth;
    }
  }

  @action
  calculateMaxHeight(popperModifierData) {
    if (!this.isDestroying) {
      this.maxOverlayHeight = calculateMaxHeight(popperModifierData);
    }
  }
}
