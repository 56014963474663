import Component from '@ember/component';
import { A } from '@ember/array';
import { assert, runInDebug } from '@ember/debug';
import { computed } from '@ember/object';
import { not, reads, readOnly, mapBy, equal, none, and, gt, or } from '@ember/object/computed';
import { hash, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import { createDropdownGroupFromGroupList } from '@intercom/pulse/lib/dropdown-group';
import defaultTo from '@intercom/pulse/lib/default-to';
import { ensureSafeComponent } from '@embroider/util';

const MAXIMUM_NUMBER_OF_ITEMS_TO_DISPLAY_WITHOUT_FILTER = 10;
const DEFAULT_FILTER_PLACEHOLDER = 'Search';

export default Component.extend({
  tagName: '',
  mode: 'inline',
  buttonIsTruncated: defaultTo(true),
  isMultiSelect: false,
  closeOnSelectItem: not('isMultiSelect'),
  selectedItem: reads('groupTree.selectedItem'),
  selectedItems: readOnly('groupTree.selectedItems'),
  selectedItemsValues: mapBy('selectedItems', 'value'),
  componentRequiresBlockUse: equal('items', undefined),
  componentDoesNotRequireBlockUse: not('componentRequiresBlockUse'),
  item: readOnly('selectedItem'),
  itemNotPresent: none('item'),
  itemPresent: not('itemNotPresent'),
  itemHasNoComponent: none('item.component'),
  itemHasComponent: not('itemHasNoComponent'),
  itemComponentDoesNotReplaceItem: not('item.componentShouldReplaceItem'),
  showSelectedItemComponent: and(
    'itemPresent',
    'itemHasComponent',
    'itemComponentDoesNotReplaceItem',
  ),
  noSelectedItemText: none('selectedItem.text'),
  selectedItemTextOrDefaultLabel: ternaryToProperty(
    'noSelectedItemText',
    'defaultLabel',
    'selectedItem.text',
  ),
  labelIsNotProvided: none('label'),
  labelIsProvided: not('labelIsNotProvided'),
  labelToDisplay: ternaryToProperty('labelIsProvided', 'label', 'selectedItemTextOrDefaultLabel'),
  filterPlaceholderIsNotPresent: none('filterPlaceholder'),
  filterPlaceholderIsPresent: not('filterPlaceholderIsNotPresent'),
  defaultFilterPlaceholder: DEFAULT_FILTER_PLACEHOLDER,
  filterPlaceholderToUse: ternaryToProperty(
    'filterPlaceholderIsPresent',
    'filterPlaceholder',
    'defaultFilterPlaceholder',
  ),
  numberOfItemsRequiresFilter: gt(
    'items.length',
    MAXIMUM_NUMBER_OF_ITEMS_TO_DISPLAY_WITHOUT_FILTER,
  ),
  shouldUseFilter: or('hasFilter', 'numberOfItemsRequiresFilter'),
  formattedGroupList: computed('items.[]', 'footerActions', 'shouldUseFilter', function () {
    let groupList = [];

    if (this.get('shouldUseFilter')) {
      groupList.push({
        isFilter: true,
        placeholder: this.get('filterPlaceholderToUse'),
      });
    }

    groupList.push({
      isMain: true,
      isFilterable: this.get('shouldUseFilter'),
      items: this.get('items'),
      hasItemsMarkableAsSelected: true,
    });

    let footerActions = this.get('footerActions');

    if (footerActions) {
      if (Array.isArray(footerActions)) {
        let items = footerActions.map((footerAction) => this.generateActionObject(footerAction));

        groupList.push({
          items,
        });
      } else {
        groupList.push({
          items: [this.generateActionObject(footerActions)],
        });
      }
    }
    return {
      items: groupList,
      selectedCountLimit: this.get('limit'),
      isFilterable: this.get('shouldUseFilter'),
    };
  }),

  get itemComponentClass() {
    return typeof this.get('item.component') === 'string'
      ? ensureSafeComponent(this.get('item.component'), this)
      : this.get('item.component');
  },

  generateActionObject(action) {
    return {
      text: action.text,
      icon: action.icon,
      description: action.description,
      onSelectItem: action.action,
      isDisabled: action.isDisabled,
      tooltipText: action.tooltipText,
      component: action.component,
      componentAttrs: action.componentAttrs,
      componentClasses: action.componentClasses,
    };
  },
  refreshGroupTree() {
    if (this.get('componentDoesNotRequireBlockUse')) {
      this.set('groupTree', createDropdownGroupFromGroupList(this.get('formattedGroupList')));
    }
  },
  updateSelected() {
    let selectedValue = this.get('selectedValue');
    if (selectedValue !== undefined) {
      this.setSelectedItemByValue(this.get('selectedValue'));
    }
  },
  init() {
    this._super(...arguments);
    this.refreshGroupTree();
    this.updateSelected();
  },
  didUpdateAttrs() {
    this._super(...arguments);
    let selectedValueChanged = this.get('selectedValue') !== this.get('oldSelectedValue');
    let formattedGroupListJSON = JSON.stringify(this.get('formattedGroupList'));
    let groupListChanged = formattedGroupListJSON !== this.get('oldFormattedGroupListJSON');
    if (groupListChanged || selectedValueChanged) {
      this.refreshGroupTree();
      this.set('oldFormattedGroupListJSON', formattedGroupListJSON);
      this.set('oldSelectedValue', this.get('selectedValue'));
    }
    this.updateSelected();
  },
  setSelectedItemByValue(value) {
    if (this.get('componentRequiresBlockUse')) {
      return;
    }
    if (this.get('isMultiSelect')) {
      this.get('groupTree').toggleSelectItemByValue(value);
    } else {
      this.get('groupTree').setSelectedItemByValue(value);
    }
  },
  onInternalSelectItem(value) {
    this.setSelectedItemByValue(value);
    this.set('previousSelectedValue', value);

    this.executePropertyIfItIsAFunction('onSelectItem', value);
    this.executePropertyIfItIsAFunction(
      'onSelectionChange',
      this.get('selectedItemsValues').slice(),
    );
  },
  onInternalFocusItem(value) {
    this.executePropertyIfItIsAFunction('onFocusItem', value);
  },
  publicAPI: hash(
    'selectedItemTextOrDefaultLabel',
    'selectedItem',
    'hasFilter',
    'filterPlaceholder',
    'isDisabled',
    'hasError',
  ),
  willRender() {
    this._super(...arguments);
    this.validate();
  },
  validate() {
    runInDebug(() => {
      this.checkForIncorrectUse(
        this.get('groupList') !== undefined,
        'Do not use the groupList interface with ic-select. Instead, use the items property.',
        'groupList',
      );

      let items = this.get('items');
      if (items !== undefined) {
        let serializedItems = A(items.filter((item) => item.items === undefined));
        let nestedItemLists = items
          .filter((item) => item.items !== undefined)
          .map((item) => item.items);
        nestedItemLists.forEach((nestedItems) => {
          nestedItems.forEach((nestedItem) => serializedItems.pushObject(nestedItem));
        });

        this.checkForIncorrectUse(
          serializedItems.find((item) => item.value === undefined && item.items === undefined) !==
            undefined,
          'Every item must have a value set.',
          'items',
        );

        serializedItems.forEach((item) => {
          this.checkForIncorrectUse(
            item.items !== undefined,
            'Items cannot be nested more than two levels deep.',
            'items',
          );
        });

        this.checkForIncorrectUse(
          this.get('limit') && !this.get('isMultiSelect'),
          'Limit cannot be used on ic-select, only ic-multiselect',
          'limit',
        );

        let selectedValue = this.get('selectedValue');
        let foundInstancesOfSelectedValue = serializedItems.filter(
          (item) => item.value === selectedValue,
        );
        this.checkForIncorrectUse(
          !(selectedValue === undefined || foundInstancesOfSelectedValue.length === 1),
          'When a selectedValue is set, that value must appear once and once only in the list of items.',
          () => {
            console.debug('selectedValue', selectedValue);
            console.debug('foundInstancesOfSelectedValue', foundInstancesOfSelectedValue);
          },
        );
      }

      this.checkForIncorrectUse(
        this.get('filterPlaceholderIsNotPresent') &&
          (this.get('numberOfItemsRequiresFilter') || this.get('hasFilter')),
        `When a filter is used in a select (whether specifically requested with hasFilter, or added because you have more than ${MAXIMUM_NUMBER_OF_ITEMS_TO_DISPLAY_WITHOUT_FILTER} items), you need to specify a filterPlaceholder that describes the type of information you're filtering, e.g. "Search tags" or "Search user data".`,
        'filterPlaceholder',
      );
    });
  },
  checkForIncorrectUse(incorrectlyUsed, message, propertyNameOrCallback) {
    let componentName = this.get('componentName');
    if (componentName === undefined) {
      componentName = 'ic-base-component';
    }
    let assertMessage = `${componentName}: ${message}`;
    if (incorrectlyUsed) {
      console.debug(assertMessage);
      if (typeof propertyNameOrCallback === 'string') {
        let property = this.get(propertyNameOrCallback);
        console.debug(
          `Property "${propertyNameOrCallback}" has a value of`,
          property,
          `with typeof ${typeof property}.`,
        );
      } else if (typeof propertyNameOrCallback === 'function') {
        propertyNameOrCallback();
      }
    }
    assert(assertMessage, !incorrectlyUsed);
  },
  executePropertyIfItIsAFunction() {
    let args = Array.prototype.slice.call(arguments);

    let propertyName = args.shift();

    let property = this.get(propertyName);
    if (typeof property === 'function') {
      property.apply(this, args);
    }
  },
});
