import Component from '@glimmer/component';
import { action } from '@ember/object';
import { ensureSafeComponent } from '@embroider/util';

export default class IcDropdownGroupItemComponent extends Component {
  get itemComponentClass() {
    return typeof this.args.item.component === 'string'
      ? ensureSafeComponent(this.args.item.component, this)
      : this.args.item.component;
  }

  @action
  focusItemIfPresent(item) {
    if (this.args.focusItem) {
      this.args.focusItem(item);
    }
  }
}
