import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { notEmpty, and, filterBy } from '@ember/object/computed';
import { A } from '@ember/array';
import { schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import keycodes from '@intercom/pulse/lib/keynames';
import AriaMenuMouseAndKeybindings from '@intercom/pulse/lib/aria-menu-mouse-and-keybindings';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { ensureSafeComponent } from '@embroider/util';
import IcDropdownHeading from '@intercom/pulse/components/ic-dropdown-heading';

const HEIGHT_OF_NON_FIRST_HEADING = 40;
const HEIGHT_OF_REGULAR_DROPDOWN_ITEM = 30;
const DEFAULT_MAXIMUM_DROPDOWN_WIDTH = 500;

export default Component.extend({
  eventListenerStack: service(),
  classNameBindings: ['componentClasses', 'jsBindingClass', 'testBindingClass'],
  componentClasses: 'ds-new__dropdown__wrapper flex flex-col',
  attributeBindings: ['data-test-dropdown'],
  'data-test-dropdown': true,
  minWidthOfOpenerWidth: true,
  dropdownStyle: computed('overlayWidth', 'maxOverlayHeight', function () {
    let styles = [];
    let overlayWidth = this.get('overlayWidth');
    if (overlayWidth) {
      styles.push(`min-width: ${overlayWidth}px`);
      styles.push(`max-width: ${overlayWidth}px`);
    }
    let maxOverlayHeight = this.get('maxOverlayHeight');
    if (maxOverlayHeight) {
      styles.push(`max-height: ${maxOverlayHeight}px`);
    }
    return styles ? sanitizeHtml(styles.join('; ')) : null;
  }),
  overlayWidth: computed(
    'minWidthOfOverlay',
    'minWidthOfOpenerWidth',
    'openerWidth',
    'constrainToOpener',
    'overlayWidthOnFirstRender',
    function () {
      let openerWidth = this.get('openerWidth');
      let overlayWidthOnFirstRender = this.get('overlayWidthOnFirstRender');
      let minWidthOfOverlay = this.get('minWidthOfOverlay');
      if (openerWidth && this.get('constrainToOpener')) {
        return openerWidth;
      } else if (!openerWidth || !overlayWidthOnFirstRender) {
        return minWidthOfOverlay || null;
      } else {
        minWidthOfOverlay = minWidthOfOverlay || 0;
        let minWidthOfOpenerWidth = !(this.get('minWidthOfOpenerWidth') === false);
        if (!minWidthOfOverlay && minWidthOfOpenerWidth) {
          minWidthOfOverlay = openerWidth;
        }
        // As far as the browser is concerned we have enough space to render
        // the content, but truncation or wrapping occurs when we don't have
        // the magic `+ 1` here. This is a relic of the initial IcDropdown
        // implementation and I do not fully understand it. We should revisit
        // this when we get the chance to simplify these early ds components
        // with Octane paradigms - @22a
        let maximumWidth = Math.min(overlayWidthOnFirstRender + 1, DEFAULT_MAXIMUM_DROPDOWN_WIDTH);
        return Math.max(minWidthOfOverlay, maximumWidth);
      }
    },
  ),
  internalGroupList: [],
  groupTreeContainsGroupOfType(typeOfGroup) {
    let groupTreeItems = this.get('groupTree.items');
    return groupTreeItems === undefined ? A() : A(groupTreeItems.filterBy(typeOfGroup, true));
  },
  mainGroups: computed('groupTree.items.[]', function () {
    return this.groupTreeContainsGroupOfType('isMain');
  }),
  hasMainGroup: notEmpty('mainGroups'),
  filterGroups: computed('groupTree.items.[]', function () {
    return this.groupTreeContainsGroupOfType('isFilter');
  }),
  hasFilterGroup: notEmpty('filterGroups'),
  hasFilterGroupAndMainGroup: and('hasFilterGroup', 'hasMainGroup'),
  itemsShouldSelectWithValueProperty: false,
  ariaRole: 'menu',
  didInsertElement() {
    this._super(...arguments);
    this.eventListenerStack.pushEventListener(this, 'keydown', this.handleKeypress);
  },
  willDestroyElement() {
    let groupTree = this.get('groupTree');
    if (groupTree !== undefined) {
      groupTree.setAllFilterTexts('');
    }
    this.tearDownAriaMenu();
    this._super(...arguments);
  },
  handleKeypress: action(function (event) {
    if (event.keyCode === keycodes.escape) {
      this.hide();
    }
  }),
  get dropdownComponentClass() {
    return typeof this.dropdownComponent === 'string'
      ? ensureSafeComponent(this.dropdownComponent, this)
      : this.dropdownComponent;
  },
  scrollToSelectedItem() {
    if (this.get('hasMainGroup')) {
      let main = this.get('mainGroups').objectAt(0);
      let calculatedTop = 0;
      let items = main.get('serializedItemsToDisplay');
      let selectedItemFound = false;
      for (let i = 0, l = items.length; i < l; i++) {
        if (items.objectAt(i).get('isSelected')) {
          selectedItemFound = true;
          break;
        }
        calculatedTop +=
          i > 0 && items.objectAt(i).get('component') === IcDropdownHeading
            ? HEIGHT_OF_NON_FIRST_HEADING
            : HEIGHT_OF_REGULAR_DROPDOWN_ITEM;
      }
      if (selectedItemFound) {
        let mainElement = $('.o__main', this.element);
        let scrollHeight = mainElement.get(0).scrollHeight;
        let scrollWindow = mainElement.get(0).clientHeight;
        let maxScroll = scrollHeight - scrollWindow;
        let scrollPosition = Math.max(
          Math.min(calculatedTop - (scrollWindow - HEIGHT_OF_REGULAR_DROPDOWN_ITEM) / 2, maxScroll),
          0,
        );
        mainElement.scrollTop(scrollPosition);
      }
    }
  },
  postSetup() {
    this.scrollToSelectedItem();
    this.setupAriaMenu();
  },
  setupAriaMenu() {
    if (this.isDestroying) {
      return;
    }
    let ariaMenu = AriaMenuMouseAndKeybindings.create({
      context: $('.ds-new__dropdown', this.element),
      onTabOut: () => {
        this.hide();
      },
    });
    ariaMenu.setup();
    this.set('ariaMenu', ariaMenu);
  },
  tearDownAriaMenu() {
    let ariaMenu = this.get('ariaMenu');
    if (ariaMenu !== undefined) {
      ariaMenu.teardown();
    }
  },
  selectItem: action(function (item) {
    if (
      typeof item.get !== 'function' ||
      !(item.get('isDisabled') || item.get('isLimitDisabled'))
    ) {
      if (typeof item.get !== 'function') {
        this.openerSelectItem(item);
      } else if (typeof item.onSelectItem === 'function') {
        item.onSelectItem();
        item.set('isSelected', item.get('canBeMarkedAsSelected') && !item.get('isSelected'));
      } else if (item.get('value') !== undefined) {
        this.openerSelectItem(item.get('value'));
      } else {
        this.openerSelectItem(item);
        item.set('isSelected', item.get('canBeMarkedAsSelected') && !item.get('isSelected'));
      }
      if (this.get('closeOnSelectItem')) {
        this.hide();
      }
    }
  }),
  focusItem: action(function (item) {
    if (typeof item.get === 'function' && item.get('value') !== undefined) {
      this.openerFocusItem(item.get('value'));
    } else {
      this.openerFocusItem(item);
    }
  }),
  afterOverlayVisible: action(function (element) {
    if (this.isDestroying) {
      return;
    }
    this.set('overlayWidthOnFirstRender', element.offsetWidth);
    schedule('afterRender', () => {
      if (!this.isDestroying) {
        schedule('afterRender', () => this.postSetup());
      }
    });
  }),
  passedFilterGroups: filterBy('groupList', 'isFilter', true),
  passedGroupListContainsFilterGroup: notEmpty('passedFilterGroups'),
});
