import { computed, get } from '@ember/object';

export default function rejectBy(dependentKey, propertyKey, value) {
  return computed(`${dependentKey}.@each.${propertyKey}`, function () {
    let collection = get(this, dependentKey);
    if (!collection || !collection.rejectBy) {
      return [];
    }
    return collection.rejectBy(propertyKey, value);
  });
}
