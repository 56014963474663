import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class Option extends Component {
  @action
  onClick() {
    if (!this.args.isDisabled) {
      this.args.onSelect(this.args.value);
    }
  }
}
