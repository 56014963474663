import { helper } from '@ember/component/helper';
import { assert } from '@ember/debug';
import {
  ASSET_MAP_PROPERTY_NAME_ON_WINDOW,
  ASSET_MAP_STATUS_PROPERTY_NAME_ON_WINDOW,
  initialize as initializeBroccoliAssetMap,
} from '@intercom/pulse/initializers/broccoli-source-map-loader';

function _assetUrl(positional, named) {
  let [url] = positional;
  if (
    typeof url !== 'string' ||
    positional.length > 1 ||
    (named && Object.keys(named).length > 0)
  ) {
    assert(
      `The {{asset-url}} helper expects exactly 1 positional argument of type "string" - eg. {{asset-url "/images/logo.png"}} - you provided positional params: ${positional} and named params: ${JSON.stringify(
        named,
      )}`,
    );
  }

  if (url.startsWith('http')) {
    assert(
      "The {{asset-url}} helper is only used when referring to an image in the app's public directory (because these will get fingerprinted in production builds) - do not pass fully qualified URLs.",
    );
  }

  if (url.startsWith('/')) {
    url = url.slice(1);
  } else {
    assert(
      'The {{asset-url}} helper expects asset paths relative to the root of the public directory, and for the path to contain a leading `/` by convention. eg. {{asset-url "/images/correct.png"}} instead of {{asset-url "images/DO-NOT-DO-THIS.png"}}',
    );
  }

  if (!window[ASSET_MAP_STATUS_PROPERTY_NAME_ON_WINDOW]) {
    initializeBroccoliAssetMap();
  }
  let assetMap = window[ASSET_MAP_PROPERTY_NAME_ON_WINDOW];
  if (assetMap?.assets?.[url]) {
    let prepend = assetMap.prepend || '';
    return prepend + assetMap.assets[url];
  } else if (assetMap?.prepend) {
    // we don't have an asset mapping but we do have a prepend, this is the
    // case in local development builds. in this scenario we want to prenend
    // the non-fingerprinted asset paths
    let prepend = assetMap.prepend || '';
    return prepend + url;
  }

  // if all else fails and we didn't find a mapping for the given url, fallback
  // to returning the url unaltered so that error messages are helpful
  return positional[0];
}

export function assetUrl(url) {
  return _assetUrl([url]);
}

export default helper(_assetUrl);
