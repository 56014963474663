import $ from 'jquery';
import { computed } from '@ember/object';
import { notEmpty, not } from '@ember/object/computed';
import ICBaseComponent from './ic-base-component';
import defaultTo from '@intercom/pulse/lib/default-to';
import { inject as service } from '@ember/service';

export default ICBaseComponent.extend({
  intl: service(),

  componentClasses: computed('isOneLine', function () {
    let classes = ['filter-block__container'];
    if (this.get('isOneLine')) {
      classes.push('o__one-line');
    }
    return classes.join(' ');
  }),
  hasAppearance: notEmpty('appearance'),
  appearanceClass: computed('appearance', function () {
    return `o__${this.get('appearance')}`;
  }),
  renderedName: computed('isOneLine', 'name', 'nameWithSpaces', function () {
    return this.get('isOneLine') ? this.get('name') : this.get('nameWithSpaces');
  }),
  nameWithSpaces: computed('name', function () {
    let wordDividerRegex = /(\.|-|_|\|)/g;
    return this.get('name').replace(wordDividerRegex, '$1\u200b');
  }),
  blockClasses: computed(
    'displaysFullDescription',
    'isInvalid',
    'isOpen',
    'isClickable',
    'hasAppearance',
    'showDelete',
    function () {
      let classes = ['filter-block', 'card'];
      if (this.get('displaysFullDescription')) {
        classes.push('o__full-description');
      }
      if (this.get('isInvalid')) {
        classes.push('o__error');
      }
      if (this.get('hasAppearance')) {
        classes.push(this.get('appearanceClass'));
      }
      if (this.get('isOpen')) {
        classes.push('o__open');
      }
      if (this.get('isClickable')) {
        classes.push('o__clickable');
      }
      if (this.get('showDelete')) {
        classes.push('o__deletable');
      }
      return classes.join(' ');
    },
  ),
  name: defaultTo(''),
  details: defaultTo(''),
  attributeDescription: defaultTo(''),
  formattedAttributeDescription: computed('attributeDescription', function () {
    if (!this.attributeDescription?.trim()) {
      return '';
    }
    // Ensure that description always ends with period: https://github.com/intercom/intercom/issues/45181
    return `${this.attributeDescription}${this.attributeDescription.endsWith('.') ? '' : '.'}`;
  }),
  icon: defaultTo(''),
  mode: defaultTo('default'),
  isOpen: defaultTo(false),
  isClosed: not('isOpen'),
  isClickable: defaultTo(false),
  showDelete: notEmpty('onDelete'),
  deleteTooltipContent: null,
  invalidMessage: null,
  click(event) {
    if ($(event.target).closest('.filter-block__delete').length) {
      this.get('onDelete')();
    } else if (this.get('onClick')) {
      this.get('onClick')();
    }
  },

  init() {
    this._super(...arguments);

    if (!this.get('deleteTooltipContent')) {
      this.set('deleteTooltipContent', this.intl.t('pulse.ic-filter-block.delete-tooltip-content'));
    }

    if (!this.get('invalidMessage')) {
      this.set('invalidMessage', this.intl.t('pulse.ic-filter-block.invalid-message'));
    }
  },
});
