import { get, computed } from '@ember/object';

export default function findBy(dependentKey, propertyKey, value) {
  value = value || true;
  return computed(`${dependentKey}.@each.${propertyKey}`, function () {
    let collection = get(this, dependentKey);
    if (!collection || !collection.findBy) {
      return;
    }
    return collection.findBy(propertyKey, value);
  });
}
