import $ from 'jquery';
import { bind } from '@ember/runloop';
import EmberObject from '@ember/object';
import KeyNames from '@intercom/pulse/lib/keynames';
import { task, timeout } from 'ember-concurrency';

export const MENUITEM_SELECTOR = '[role^="menuitem"]';
export const MENUITEM_AND_INPUT_SELECTOR = `${MENUITEM_SELECTOR}, input`;
const FILTER_SELECTOR = '.js__filter-role';
const DELAY_AFTER_FORCED_SCROLL_UNTIL_WE_PROCESS_MOUSEENTERS = 250;
const DELAY_UNTIL_TYPEAHEAD_STRING_IS_RESET = 1000;

export default EmberObject.extend({
  processMouseEnters: true,
  hasFilter: false,
  currentTypeAheadString: '',

  setup() {
    let context = this.get('context');
    if (context.find(MENUITEM_SELECTOR).length > 0) {
      context.on(
        'mouseenter.aria-menu-mouse-and-keybindings',
        MENUITEM_SELECTOR,
        bind(this, (e) => this.handleMouseEnter(e)),
      );
      context.on(
        'keydown.aria-menu-mouse-and-keybindings',
        MENUITEM_SELECTOR,
        bind(this, (e) => this.handleKeyDown(e)),
      );
      context.on(
        'keyup.aria-menu-mouse-and-keybindings',
        MENUITEM_AND_INPUT_SELECTOR,
        bind(this, (e) => this.handleKeyUp(e)),
      );
      context.on(
        'keypress.aria-menu-mouse-and-keybindings',
        MENUITEM_AND_INPUT_SELECTOR,
        bind(this, (e) => this.handleKeyPress(e)),
      );
      this.focusOnFirstItem();
    }
    if (this.getFilter().length === 1) {
      this.set('hasFilter', true);
    }
  },
  teardown() {
    let context = this.get('context');
    context.off('mouseenter.aria-menu-mouse-and-keybindings');
    context.off('keydown.aria-menu-mouse-and-keybindings');
    context.off('keyup.aria-menu-mouse-and-keybindings');
    context.off('keypress.aria-menu-mouse-and-keybindings');
  },

  // Utils
  getMenuItems() {
    let context = this.get('context');
    return context
      .find(`${MENUITEM_AND_INPUT_SELECTOR}`)
      .not('[disabled],[aria-disabled=true],[aria-disabled]');
  },
  getFocusedItem() {
    return this.getFocusedItemAndIndex()[0];
  },
  getFocusedIndex() {
    return this.getFocusedItemAndIndex()[1];
  },
  getFocusedItemAndIndex() {
    let menuItems = this.getMenuItems();
    for (let i = 0, l = menuItems.length; i < l; i++) {
      if (menuItems.eq(i).is(':focus')) {
        return [menuItems.eq(i), i];
      }
    }
  },
  getItemWithFallBack(jQueryElement, fallbackElement) {
    return jQueryElement.length === 0 ? fallbackElement : jQueryElement;
  },
  getPreviousItem() {
    return this.getAdjacentItem(-1);
  },
  getNextItem() {
    return this.getAdjacentItem(1);
  },
  getAdjacentItem(delta) {
    let menuItems = this.getMenuItems();
    let focusedItemIndex = this.getFocusedIndex();
    let soughtItemIndex = focusedItemIndex + delta;
    if (soughtItemIndex < 0) {
      return menuItems.find('');
    }
    return menuItems.eq(soughtItemIndex);
  },
  getFilter() {
    return this.getMenuItems().filter(FILTER_SELECTOR);
  },
  getLastItem() {
    return this.getMenuItems().filter(':last');
  },
  getFirstItem() {
    return this.getMenuItems().eq(0);
  },

  // Event handlers
  handleKeyUp(e) {
    let hasFilter = this.get('hasFilter');
    let elementIsNotInput = e.currentTarget.tagName.toUpperCase() !== 'INPUT';
    let handled = false;

    if (e.keyCode === KeyNames.tab) {
      let onTabOut = this.get('onTabOut');
      if (typeof onTabOut === 'function') {
        onTabOut();
      }
      return true;
    }

    if (e.altKey || e.ctrlKey || e.shiftKey) {
      return true;
    }

    switch (e.keyCode) {
      case KeyNames.space:
        if (elementIsNotInput) {
          this.activateCurrentlyFocusedItem();
          handled = true;
        }
        break;
      case KeyNames.enter:
        this.activateCurrentlyFocusedItem();
        handled = true;
        break;
      case KeyNames.home:
        if (elementIsNotInput) {
          this.focusOnFirstItem();
          handled = true;
        }
        break;
      case KeyNames.end:
        if (elementIsNotInput) {
          this.focusOnLastItem();
          handled = true;
        }
        break;
      case KeyNames.up:
        this.focusOnPreviousItem();
        handled = true;
        break;
      case KeyNames.down:
        this.focusOnNextItem();
        handled = true;
        break;
      default:
        if (!elementIsNotInput) {
          return true;
        }
    }

    e.stopPropagation();

    if (handled) {
      e.preventDefault();
    }

    if (elementIsNotInput && !handled) {
      if (hasFilter) {
        let filter = this.getFilter();
        this.focusOnFilter();
        filter.trigger(e);
      } else {
        // Keyboard type search
        this.addToTypeAheadString(e.keyCode);
      }
      return true;
    } else {
      return false;
    }
  },
  handleKeyDown(e) {
    if (e.altKey || e.ctrlKey || e.shiftKey) {
      return true;
    }

    switch (e.keyCode) {
      case KeyNames.enter:
      case KeyNames.home:
      case KeyNames.end:
      case KeyNames.left:
      case KeyNames.right:
      case KeyNames.up:
      case KeyNames.down: {
        e.stopPropagation();
        return false;
      }
    }

    return true;
  },
  handleKeyPress(e) {
    if (e.altKey || e.ctrlKey || e.shiftKey) {
      return true;
    }

    switch (e.keyCode) {
      case KeyNames.enter:
      case KeyNames.home:
      case KeyNames.end:
      case KeyNames.left:
      case KeyNames.right:
      case KeyNames.up:
      case KeyNames.down: {
        e.stopPropagation();
        return false;
      }
      case KeyNames.tab: {
        let onTabOut = this.get('onTabOut');
        if (typeof onTabOut === 'function') {
          onTabOut();
        }
      }
    }

    return true;
  },
  handleMouseEnter(e) {
    if (!this.get('processMouseEnters')) {
      return;
    }
    let context = this.get('context');
    let target = context.find(e.currentTarget);
    if (this.elementIsFullyVisible(target) && this.getMenuItems().is(target)) {
      target.focus();
    }
  },
  addToTypeAheadString(keyCode) {
    let typeAheadString =
      this.get('currentTypeAheadString') + String.fromCharCode(keyCode).toLowerCase();
    let context = this.get('context');
    this.set('currentTypeAheadString', typeAheadString);
    let matchingMenuItems = $.grep(this.getMenuItems(), (menuItem) =>
      context.find(menuItem).text().trim().toLowerCase().startsWith(typeAheadString),
    );
    if (matchingMenuItems.length > 0) {
      context.find(matchingMenuItems[0]).focus();
    }
    this.get('clearTypeAheadStringWhenAppropriate').perform();
  },

  clearTypeAheadStringWhenAppropriate: task(function* () {
    yield timeout(DELAY_UNTIL_TYPEAHEAD_STRING_IS_RESET);
    this.set('currentTypeAheadString', '');
  }).restartable(),

  maskImmediateMouseEnters() {
    this.set('processMouseEnters', false);
    this.get('maskImmediateMouseEntersTask').perform();
  },

  maskImmediateMouseEntersTask: task(function* () {
    yield timeout(DELAY_AFTER_FORCED_SCROLL_UNTIL_WE_PROCESS_MOUSEENTERS);
    this.set('processMouseEnters', true);
  }).restartable(),

  // jQuery work
  getBestContainer(jQueryElement) {
    if (jQueryElement.get(0) === undefined) {
      // No element
      return this.get('context');
    } else {
      let parentMainElement = jQueryElement.parents('.o__main');
      if (parentMainElement.length === 0) {
        return this.get('context');
      } else {
        return parentMainElement;
      }
    }
  },
  scrollToCenter(jQueryElement) {
    if (jQueryElement.get(0) === undefined) {
      return;
    }
    let container = this.getBestContainer(jQueryElement);
    let top = jQueryElement.position().top;
    let itemHeight = jQueryElement.get(0).offsetHeight;
    let scrollWindowHeight = container.get(0).clientHeight;
    let desiredTop = (scrollWindowHeight - itemHeight) / 2;
    let currentScrollTop = container.get(0).scrollTop;
    let scrollPosition = top - desiredTop + currentScrollTop;
    this.maskImmediateMouseEnters();
    container.scrollTop(scrollPosition);
  },
  elementIsFullyVisible(el) {
    let context = this.get('context');
    let jQueryElement = context.find(el);
    let container = this.getBestContainer(jQueryElement);
    let top = jQueryElement.position().top;
    if (top < 0) {
      return false;
    }
    let topOfBottom = top + jQueryElement.outerHeight();
    return topOfBottom < container.outerHeight();
  },
  elementIsInVerticalCollection(jQueryElement) {
    // @antidis - occluded-content is a tag added by vertical-collection
    return this.getBestContainer(jQueryElement).find('occluded-content').length > 0;
  },
  processFocusOnPreviousItem() {
    let jQueryElement = this.getPreviousItem();
    this.processFocusOn(jQueryElement);
  },
  processFocusOnNextItem() {
    let jQueryElement = this.getNextItem();
    this.processFocusOn(jQueryElement);
  },
  processFocusOn(jQueryElement) {
    jQueryElement.focus();
    this.scrollToCenter(jQueryElement);
  },

  // Actions
  focusOnFirstItem() {
    this.getFirstItem().focus();
  },
  focusOnLastItem() {
    this.getLastItem().focus();
  },
  focusOnPreviousItem() {
    this.processFocusOnPreviousItem();
  },
  focusOnNextItem() {
    this.processFocusOnNextItem();
  },
  focusOnFilter() {
    this.getFilter().focus();
  },
  activateCurrentlyFocusedItem() {
    let item = this.getFocusedItem();
    // Focus on next element for input boxes
    if (item.is('input')) {
      item = this.getNextItem();
    }
    item.trigger('click');
  },
});
