import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import keycodes from '@intercom/pulse/lib/keynames';
import { inject as service } from '@ember/service';

const FOCUSABLE_TAGS = 'a, button, textarea, input, select';

const getFocusableChildren = (element) =>
  Array.from(element?.querySelectorAll(FOCUSABLE_TAGS) || []).filter((t) => !t.disabled);

export default class ModalContainer extends Component {
  @service eventListenerStack;
  @tracked displayHeaderScrollIndicatorLine = false;
  element;

  @action
  onDidInsert(element) {
    this.eventListenerStack.pushEventListener(this, 'keydown', this.handleKeypress);
    if (this.args.focusElement === 'body') {
      this.focusFirstFocusableElement(element);
    } else {
      this.focusFooterButton(element);
    }
    // save a reference to the modal element so that we can perform focus
    // trapping later on in the component lifecycle
    this.element = element;
  }

  @action
  focusFirstFocusableElement(element) {
    // Focus on the first valid focus element that isn't the close button
    let focusableElements = getFocusableChildren(element);
    if (focusableElements) {
      if (this.args.showCloseIcon && focusableElements.length > 1) {
        focusableElements[1].focus();
      } else if (focusableElements.length) {
        focusableElements[0].focus();
      }
    }
  }

  @action
  focusFooterButton(element) {
    let buttonSelector =
      this.args.focusElement === 'secondary'
        ? 'button.o__secondary'
        : 'button[class*="o__primary"]';
    let buttonElement = element.querySelector(buttonSelector);
    if (buttonElement) {
      buttonElement.focus();
    }
  }

  @action
  handleKeypress(event) {
    switch (event.keyCode) {
      case keycodes.escape:
        this.handleEscapeKeypress(event);
        break;
      case keycodes.tab:
        this.handleTabKeypress(event);
        break;
    }
  }

  @action
  handleEscapeKeypress(_event) {
    if (this.args.closeOnEscape) {
      this.args.onModalClose();
    }
  }

  // Implement "focus trapping" where the focus wraps from the bottom of the
  // modal to the top (and vice versa) such that the user is never interacting
  // with elements behind the modal
  @action
  handleTabKeypress(event) {
    let focusableElements = getFocusableChildren(this.element);
    if (focusableElements) {
      let firstFocusable = focusableElements[0];
      let lastFocusable = focusableElements[focusableElements.length - 1];
      if (event.shiftKey) {
        if (document.activeElement === firstFocusable) {
          lastFocusable.focus();
          event.preventDefault();
        }
      } else if (document.activeElement === lastFocusable) {
        firstFocusable.focus();
        event.preventDefault();
      }
    }
  }

  @action
  handleBodyScrollEvent(event) {
    this.displayHeaderScrollIndicatorLine = this.args.internalScroll && event.target.scrollTop > 0;
  }
}
