// We use this helper to yield an empty POJO which can be written to and read
// from inside template only glimmer components. More info:
// https://github.com/emberjs/ember.js/issues/17202#issuecomment-437927226

import Helper from '@ember/component/helper';

export default Helper.extend({
  init() {
    this._super(...arguments);

    this._data = {};
  },

  compute() {
    return this._data;
  },
});
