import Component from '@glimmer/component';
import { ensureSafeComponent } from '@embroider/util';

export default class IcDropdownGroupComponent extends Component {
  get groupComponentClass() {
    return typeof this.args.group.component === 'string'
      ? ensureSafeComponent(this.args.group.component, this)
      : this.args.group.component;
  }
}
