import { computed } from '@ember/object';
import { or } from '@ember/object/computed';
import { fmtStyle } from '@intercom/pulse/lib/computed-properties';
import ICBaseComponent from './ic-base-component';
import { ensureSafeComponent } from '@embroider/util';

const MINIMUM_HEIGHT_OF_DROPDOWN_ITEM = 30;

export default ICBaseComponent.extend({
  topLevelGroup: false,
  isMain: false,
  isActuallyStandard: false,
  isMainGroup: or('group.isMain', 'isMain'),
  isActuallyStandardGroup: or('group.isActuallyStandard', 'isActuallyStandard'),
  dropdownGroupClasses: computed('isMainGroup', 'isActuallyStandardGroup', function () {
    let p = this.getProperties('isMainGroup', 'isActuallyStandardGroup');
    return `ds-new__dropdown__group
            ${p.isActuallyStandardGroup ? 'o__standard' : ''}
            ${p.isMainGroup ? 'o__main u__relative' : ''}`;
  }),
  flexClasses: computed('isMainGroup', function () {
    return this.get('isMainGroup') ? 'flex-auto' : 'flex-none';
  }),
  componentClasses: computed('topLevelGroup', 'dropdownGroupClasses', 'flexClasses', function () {
    let p = this.getProperties('topLevelGroup', 'dropdownGroupClasses', 'flexClasses');
    if (p.topLevelGroup) {
      return `${p.flexClasses} ${p.dropdownGroupClasses}`;
    } else {
      return '';
    }
  }),
  minHeight: computed('group.itemsToDisplay.length', function () {
    return MINIMUM_HEIGHT_OF_DROPDOWN_ITEM * this.get('group.itemsToDisplay.length');
  }),
  collectionWrapperStyle: fmtStyle('min-height: %@px;', 'minHeight'),

  get groupHeadingComponentClass() {
    return typeof this.group.headingComponent === 'string'
      ? ensureSafeComponent(this.group.headingComponent, this)
      : this.group.headingComponent;
  },
});
