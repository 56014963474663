import { computed, get } from '@ember/object';
import { typeOf, isEmpty } from '@ember/utils';

export default function valueOrDefault(dependentKey, defaultValue) {
  return computed(dependentKey, function () {
    let value = get(this, dependentKey);
    let trimmedValue = value;
    if (typeOf(value) === 'string') {
      trimmedValue = value.trim();
    }
    return isEmpty(trimmedValue) ? defaultValue : value;
  });
}
