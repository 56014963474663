import Component from '@ember/component';
import { set, computed } from '@ember/object';
import { map } from '@ember/object/computed';
import { fmtStyle } from '@intercom/pulse/lib/computed-properties';
import defaultTo from '@intercom/pulse/lib/default-to';
import { assert } from '@ember/debug';

export default Component.extend({
  classNames: ['ds-new__table__scroll-container'],
  // When a table has horizontal scroll (when the minWidth option is set) this
  // creates a new overflow context ( overflow-y: scroll ). This means that the
  // table’s nearest overflow context is not the full page which results in
  // sticky positioned elements not sticking correctly to the top of the
  // vertical scroll container: https://github.com/w3c/csswg-drafts/issues/865
  classNameBindings: ['minWidth:o__scrolls-horizontally'],
  attributeBindings: ['data-test-table-scroll-container'],
  'data-test-table-scroll-container': true,

  containerStyle: fmtStyle('max-height: %@; min-width: %@;', 'maxHeight', 'minWidth'),
  sortState: defaultTo({}),
  loadingRowCount: defaultTo(8),
  processedColumns: map('columns', (column) => {
    set(column, 'isVisible', column.isVisible !== false);
    return column;
  }),
  bulkSelectChecked: computed('rowBulkSelectionLimit', 'selectedContent.[]', function () {
    if (this.isLoading) {
      return false;
    }
    if (this.selectedContent) {
      return this.selectedContent.length === this.data.length;
    }
  }),
  bulkSelectInIndeterminateState: computed('selectedContent.[]', 'bulkSelectChecked', function () {
    if (this.bulkSelectChecked) {
      return false;
    }
    if (this.selectedContent) {
      return this.selectedContent.length < this.data.length && this.selectedContent.length > 0;
    }
  }),
  atLeastOneColumnIsSortable: computed('columns', function () {
    return this.columns.some((column) => column.isSortable);
  }),
  atLeastOneColumnIsSelectable: computed('columns', function () {
    return this.columns.some((column) => column.type === 'checkbox');
  }),

  verticalScrollContainerElement: null,
  columnsHash: computed('processedColumns', function () {
    let seenValuePaths = new Set();
    return this.processedColumns.reduce((acc, column) => {
      assert(
        `Each column must have a non-empty valuePath property of type String. Assertion failed for ${column.valuePath}`,
        typeof column.valuePath === 'string' && column.valuePath.length > 0,
      );
      assert(
        `Each column must have a unique valuePath property. \`${column.valuePath}\` was used at least twice.`,
        !seenValuePaths.has(column.valuePath),
      );
      assert(
        'You must provide an `onBulkSelect` action if any of your columns are selectable.',
        !(this.atLeastOneColumnIsSelectable && typeof this.onBulkSelect !== 'function'),
      );
      seenValuePaths.add(column.valuePath);
      return Object.assign(acc, {
        [column.valuePath]: column,
      });
    }, {});
  }),

  scrollToContainerTop() {
    if (this.verticalScrollContainerElement) {
      if (this.maxHeight) {
        this.verticalScrollContainerElement.scrollTop = 0;
      } else {
        let { y: verticalScrollContainerYPosition } =
          this.verticalScrollContainerElement.getBoundingClientRect();
        if (verticalScrollContainerYPosition < 0) {
          this.verticalScrollContainerElement.scrollIntoView();
        }
      }
    }
  },

  willRender() {
    this._super(...arguments);
    assert(
      'You must provide an `onSort` action if any of your columns are sortable.',
      !(this.atLeastOneColumnIsSortable && typeof this.onSort !== 'function'),
    );
    assert(
      'You must provide an `onLoadMore` action if `canLoadMore` is set.',
      !this.canLoadMore || typeof this.onLoadMore === 'function',
    );
  },

  didRender() {
    this._super(...arguments);
    this.set(
      'verticalScrollContainerElement',
      this.element.querySelector('[data-table-container]'),
    );
  },

  actions: {
    _onColumnHeaderClick(valuePath, type) {
      if (!this.isLoading) {
        if (typeof this.onSort === 'function' && type === 'sort') {
          this.onSort(valuePath);
          this.scrollToContainerTop();
        }
        if (type === 'select') {
          this.onBulkSelect();
        }
      }
    },
  },
});
