import ICBaseComponent from './ic-base-component';
import { ensureSafeComponent } from '@embroider/util';

export default ICBaseComponent.extend({
  tagName: 'th',
  componentClasses: 'table__cell',
  toggleSort() {},
  largestMaxWidth: 1000,

  get columnDescriptionComponentClass() {
    return typeof this.column.description.component === 'string'
      ? ensureSafeComponent(this.column.description.component, this)
      : this.column.description.component;
  },
});
