import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class IcTabControl extends Component {
  @action
  onButtonClicked(value) {
    if (this.args.selectedTab !== value && this.args.onSelectionChange) {
      this.args.onSelectionChange(value);
    }
  }
}
