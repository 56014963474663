let take = function (array, length) {
  if (array.length > 0) {
    while (array.length < length) {
      array = array.concat(array);
    }
  }
  return array.slice(0, length);
};

export default take;
