import detectOverflow from '@popperjs/core/dist/esm/utils/detectOverflow';
import { DEFAULT_OPTIONS } from '@intercom/pulse/lib/popover-utils';

// A sane default for how small the dropdown can become, should be the same as
// in: app/styles/pulse/structures/ds-new/_dropdown.scss
export const MIN_OVERLAY_HEIGHT = 50; // enough to display an item and the default group padding
export const MARGIN_FOR_MAX_OVERLAY_HEIGHT =
  DEFAULT_OPTIONS.OFFSET_DISTANCE + DEFAULT_OPTIONS.PREVENT_OVERFLOW.PADDING;

// adapted from https://www.npmjs.com/package/popper-max-size-modifier to
// only compute height
export const calculateMaxHeight = ({ state, _name }) => {
  let overflow = detectOverflow(state, { boundary: DEFAULT_OPTIONS.PREVENT_OVERFLOW.BOUNDARY });
  let [basePlacement] = state.placement.split('-');
  let heightProp = basePlacement === 'top' ? 'top' : 'bottom';
  let availableViewportSpace = state.rects.popper.height - overflow[heightProp];
  return Math.max(
    MIN_OVERLAY_HEIGHT,
    Math.ceil(availableViewportSpace - MARGIN_FOR_MAX_OVERLAY_HEIGHT),
  );
};

export default {
  MARGIN_FOR_MAX_OVERLAY_HEIGHT,
  MIN_OVERLAY_HEIGHT,
  calculateMaxHeight,
};
