import Component from '@glimmer/component';
import { runInDebug } from '@ember/debug';

export const ILLUSTRATIVE_ICON_LIST = {
  acquire: { keywords: ['hotel bell'] },
  activity: {},
  'android-mobile': {},
  'api-limits': {},
  'app-store': {},
  'article-feedback': {},
  article: {},
  articles: {},
  'auto-message': {},
  attention: { keywords: ['warning'] },
  'banner-filled': {},
  'bot-filled': {},
  briefcase: {},
  camera: {},
  campaign: {},
  'carousel-filled': {},
  chart: { keywords: ['visualization', 'graph', 'chart'] },
  'chat-filled': {},
  'checklist-filled': {},
  'collection-template-classic': {},
  'collection-template-compact': {},
  'collection-template-visual': {},
  code: {},
  collection: {},
  'compact-messenger': { keywords: ['m5'] },
  company: {},
  'connect-tools': {},
  'conversation-rating': { keywords: ['smiley'] },
  conversation: {},
  'data-collection': { keywords: ['form'] },
  'delivery-window': {},
  'default-messenger': { keywords: ['m5'] },
  email: {},
  'email-filled': {},
  'email-attachment': {},
  engage: {},
  error: {},
  event: {},
  fin: { keywords: ['workflows'] },
  folder: {},
  'footer-classic': {},
  'footer-multi-level': {},
  'footer-simple': {},
  'free-up-time': {},
  github: {},
  goal: {},
  import: {},
  'inapp-announcements': {},
  integration: {},
  'ios-mobile': {},
  'lead-qualification': {},
  link: {},
  live: {},
  'manual-message': {},
  mention: {},
  message: {},
  messenger: {},
  'messenger-whatsapp': {},
  'mobile-carousel': {},
  'news-filled': {},
  'notification-desktop': {},
  'notification-inapp': {},
  'notification-mobile-push': {},
  operator: {},
  'optimize-performance': {},
  outbound: {},
  pencil: {},
  people: {},
  post: {},
  'post-filled': {},
  'product-tours-filled': {},
  'product-tours-list': {},
  'push-notification-filled': {},
  'reply-button': {},
  respond: {},
  'receive-file': {},
  'rocket-ship': {},
  rule: {},
  rss: {},
  'salesforce-sandbox': {},
  'saved-reply': {},
  settings: {},
  schedule: {},
  series: { keywords: ['campaign'] },
  sms: {},
  'stop-automatically': {},
  'survey-filled': {},
  switch: {},
  sync: { keywords: ['interoperability', 'recurring', 'loop'] },
  tag: {},
  team: {},
  ticket: {},
  token: {},
  'tooltips-product': { keywords: ['info', 'help', 'tooltip'] },
  'untracked-attribute': {},
  video: {},
  'visitor-auto-message': {},
  warning: {},
  wave: { keywords: ['hand'] },
  webhook: {},
} as const;

export type IllustrativeIconName = keyof typeof ILLUSTRATIVE_ICON_LIST;
export const ILLUSTRATIVE_ICON_NAMES = Object.keys(ILLUSTRATIVE_ICON_LIST);
export const ACCEPTABLE_APPEARANCES = ['blue', 'gray', 'black'] as const;
export const ACCEPTABLE_SIZES = ['standard', 'small'] as const;

interface Signature {
  Element: HTMLElement;
  Args: {
    name: IllustrativeIconName;
    appearance?: (typeof ACCEPTABLE_APPEARANCES)[number];
    size?: (typeof ACCEPTABLE_SIZES)[number];
  };
}

export default class IcIllustrativeIcon extends Component<Signature> {
  get svgName() {
    if (ILLUSTRATIVE_ICON_NAMES.includes(this.args.name)) {
      return `illustrative-icons/${this.args.name}`;
    }
    runInDebug(() => {
      throw new Error(
        `IcIllustrativeIcon argument error: Can't find an illustrative icon with the name "${this.args.name}".`,
      );
    });
    return null;
  }

  get appearance() {
    runInDebug(() => {
      if (this.args.appearance && !ACCEPTABLE_APPEARANCES.includes(this.args.appearance)) {
        throw new Error(
          `IcIllustrativeIcon argument error: Valid values for @appearance are ${ACCEPTABLE_APPEARANCES.join(
            ', ',
          )}. You provided ${this.args.appearance}`,
        );
      }
    });
    return this.args.appearance || 'blue';
  }
  get size() {
    runInDebug(() => {
      if (this.args.size && !ACCEPTABLE_SIZES.includes(this.args.size)) {
        throw new Error(
          `IcIllustrativeIcon argument error: Valid values for @size are ${ACCEPTABLE_SIZES.join(
            ', ',
          )}. You provided ${this.args.size}`,
        );
      }
    });
    return this.args.size || 'standard';
  }
}
