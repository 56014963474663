import { runInDebug } from '@ember/debug';

export const ROOT_PARENT_SELECTOR = '.ember-application';
export const POPOVER_CONTENT_SELECTOR = '[data-popover-content]';
export const DIRECTIONS = ['top', 'bottom', 'left', 'right'] as const;
export const INVERSE_DIRECTIONS = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
} as const;
export const DEFAULT_OPTIONS = {
  ANIMATION: {
    DURATION: 100,
    TIMING_FUNCTION: 'linear',
  },
  ARROW_OVERFLOW_PADDING: 6,
  DELAY: {
    HIDE: 0,
    SHOW: 0,
  },
  FALLBACK_PLACEMENTS: ['top'],
  HIDE_ON: ['clickout'],
  INTERACTIVE: true,
  OFFSET_DISTANCE: 8,
  OFFSET_SKID: 0,
  PLACEMENT: 'bottom',
  PREVENT_OVERFLOW: {
    MAIN_AXIS: false,
    PADDING: 4,
    // we specify the viewport as our boundary for overflow detection here
    // as the default 'clipping-parents' setting can cause unusably small
    // popover contents when the openers are children of sections of the
    // Embercom app with restrictive `overflow: hidden|auto|scroll` containers
    // https://popper.js.org/docs/v2/utils/detect-overflow/#boundary
    BOUNDARY: 'viewport',
  },
  SHOW_ON: ['click'],
};

// This evaulates to true if the current user agent supports touch, in which
// case we should allow click events to open mouseenter Popovers (otherwise
// they would be inaccessible)
// Implementation source:  https://stackoverflow.com/a/13470899/6945740
export const BROWSER_SUPPORTS_TOUCH = Boolean('ontouchstart' in window || navigator.maxTouchPoints);

// This evaulates to true if the passed element is a child of root the document.
// This is required for guarding against the edge case where we receive a
// click event for which the target is no longer in the DOM; in these scenarios
// our existing DOM lineage checks will fail so we validate that the target
// is actually in the DOM before updating the state model.
export const elementIsAttachedToTheDOM = (element?: HTMLElement | null) =>
  Boolean(element?.closest('html'));

// This function takes an element and a list of selectors and returns true
// if the element is a child of any of the selectors
export const elementIsChildOfAny = (element?: HTMLElement | null, selectors: string[] = []) => {
  if (!element?.closest) {
    runInDebug(() => {
      console.warn(`<Popover> The element passed to elementIsChildOfAny is invalid.
        Called with element: ${JSON.stringify(element)}
        Called with selectors: ${JSON.stringify(selectors)}
      `);
    });
    return false;
  }
  return selectors.some((selector) => element.closest(selector));
};

export const elementIsChildOfPopover = (element?: HTMLElement | null) =>
  elementIsChildOfAny(element, [POPOVER_CONTENT_SELECTOR]);

export default {
  BROWSER_SUPPORTS_TOUCH,
  DEFAULT_OPTIONS,
  DIRECTIONS,
  INVERSE_DIRECTIONS,
  POPOVER_CONTENT_SELECTOR,
  ROOT_PARENT_SELECTOR,
  elementIsAttachedToTheDOM,
  elementIsChildOfAny,
  elementIsChildOfPopover,
};
