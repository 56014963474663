import Service from '@ember/service';
import { addEventListener, removeEventListener } from 'ember-lifeline';
import { registerDestructor } from '@ember/destroyable';

export default Service.extend({
  stacks: {},

  pushEventListener(context, eventName, handler) {
    let eventStack = this._lookupEventStack(eventName);
    if (eventStack.length > 0) {
      let currentListener = eventStack[eventStack.length - 1];
      removeEventListener(currentListener.context, window, eventName, currentListener.handler);
    }

    let newListener = { context, handler };
    eventStack.push(newListener);
    addEventListener(context, window, eventName, handler);

    registerDestructor(context, () => this._removeEventListener(eventName, newListener));
    return newListener;
  },

  _removeEventListener(eventName, listener) {
    let eventStack = this._lookupEventStack(eventName);
    // remove the passed listener from the stack as well as any listeners
    // whose contexts have been destroyed since they were added to the stack
    let filtered = eventStack.filter((l) => l !== listener && !l.context.isDestroying);
    this.stacks[eventName] = filtered;

    let next = filtered[filtered.length - 1];
    if (next) {
      addEventListener(next.context, window, eventName, next.handler);
    }
  },

  _lookupEventStack(eventName) {
    let stacks = this.stacks;
    if (stacks[eventName] === undefined) {
      stacks[eventName] = [];
    }

    return stacks[eventName];
  },
});
