import $ from 'jquery';
import Component from '@ember/component';

export default Component.extend({
  lines: 1,
  didRender() {
    this.truncateText();
  },
  truncateText() {
    let lineHeight = this.getLineHeight();
    $(this.element).css({
      display: '-webkit-box',
      '-webkit-line-clamp': `${this.get('lines')}`,
      '-webkit-box-orient': 'vertical',
      'max-height': this.get('lines') * lineHeight,
      'min-height': lineHeight,
    });
    $(this.element).css({
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
    });
  },
  getLineHeight() {
    let lineHeight = $(this.element).css('line-height');
    if (lineHeight === 'normal') {
      lineHeight = parseInt($(this.element).css('font-size'), 10) * 1.2;
    }
    return parseInt(lineHeight, 10);
  },
});
