/* eslint-disable quote-props */

/*
 * Any updates here must also be changes in 2 other places:
 * - the CSS palette definition: app/styles/pulse/_color-palette-utilities.scss
 * - the TailwindCSS config: config/tailwind.config.js
 */

const PALETTE = {
  'blue-dark': '#4B5EB4',
  'blue-darkest': '#20284d',
  blue: '#334bfa',
  'sky-darkest': '#CFD6F7',
  'sky-dark': '#DCE1F9',
  sky: '#EEF0FC',
  black: '#222222',
  'gray-dark': '#434343',
  gray: '#757575',
  'gray-light': '#e8e8e8',
  'gray-lightest': '#f1f1f1',
  'green-dark': '#0F7134',
  green: '#1bb157',
  'green-light': '#d7efdc',
  'red-dark': '#841531',
  red: '#DB0629',
  'red-light': '#FFF0F4',
  'yellow-dark': '#423200',
  yellow: '#fbc916',
  'yellow-light': '#feedaf',
  orange: '#b24d00',
  'orange-dark': '#662c00',
  'orange-light': '#ffebdb',
  'paywall-base': '#822AF0',
  'paywall-light': '#F3EAF9',
  'paywall-dark': '#491887',
  'off-white': '#f7f7f7',
  white: '#ffffff',
  'border-shadow-dark': 'rgba(0, 0, 0, 0.3)',
  'border-shadow': 'rgba(0, 0, 0, 0.15)',
  'border-shadow-light': 'rgba(0, 0, 0, 0.05)',
  transparent: 'transparent',
  'vis-yellow-20': '#804a00',
  'vis-yellow-30': '#a86b00',
  'vis-yellow-40': '#d19600',
  'vis-yellow-50': '#f0ca00',
  'vis-yellow-60': '#ffdb1a',
  'vis-yellow-70': '#fae15c',
  'vis-yellow-80': '#faefaf',
  'vis-orange-20': '#8c3123',
  'vis-orange-30': '#c24a27',
  'vis-orange-40': '#db6d2e',
  'vis-orange-50': '#eb982f',
  'vis-orange-60': '#efae5c',
  'vis-orange-70': '#fccb8e',
  'vis-orange-80': '#fae4c8',
  'vis-salmon-20': '#992626',
  'vis-salmon-30': '#bd3e3a',
  'vis-salmon-40': '#de5e54',
  'vis-salmon-50': '#e87e6d',
  'vis-salmon-60': '#ee9d91',
  'vis-salmon-70': '#fabcb2',
  'vis-salmon-80': '#fcdad4',
  'vis-pink-20': '#8a2266',
  'vis-pink-30': '#ab3473',
  'vis-pink-40': '#cc5683',
  'vis-pink-50': '#de789d',
  'vis-pink-60': '#e694b3',
  'vis-pink-70': '#fab1cb',
  'vis-pink-80': '#fad0df',
  'vis-magenta-20': '#70298a',
  'vis-magenta-30': '#963888',
  'vis-magenta-40': '#c44db5',
  'vis-magenta-50': '#d971c8',
  'vis-magenta-60': '#ea8bda',
  'vis-magenta-70': '#f6a7e9',
  'vis-magenta-80': '#fac8f2',
  'vis-violet-20': '#6d24bd',
  'vis-violet-30': '#8e33cc',
  'vis-violet-40': '#b24dd7',
  'vis-violet-50': '#ca72e9',
  'vis-violet-60': '#d591ed',
  'vis-violet-70': '#e1aef2',
  'vis-violet-80': '#eecdfa',
  'vis-slateblue-20': '#0f4abf',
  'vis-slateblue-30': '#5447ff',
  'vis-slateblue-40': '#6d66ff',
  'vis-slateblue-50': '#938aff',
  'vis-slateblue-60': '#ada7f6',
  'vis-slateblue-70': '#c0baff',
  'vis-slateblue-80': '#d9d7fa',
  'vis-blue-20': '#045894',
  'vis-blue-30': '#1261ff',
  'vis-blue-40': '#307fff',
  'vis-blue-50': '#6ba1ff',
  'vis-blue-60': '#85b1ff',
  'vis-blue-70': '#a3c3ff',
  'vis-blue-80': '#cddcfa',
  'vis-azure-20': '#015e75',
  'vis-azure-30': '#0074c7',
  'vis-azure-40': '#1b94e3',
  'vis-azure-50': '#50b2f2',
  'vis-azure-60': '#6cbbef',
  'vis-azure-70': '#8ecffd',
  'vis-azure-80': '#c3e2f7',
  'vis-cyan-20': '#006175',
  'vis-cyan-30': '#0083a1',
  'vis-cyan-40': '#00a7d1',
  'vis-cyan-50': '#44bede',
  'vis-cyan-60': '#63c5de',
  'vis-cyan-70': '#83dcf3',
  'vis-cyan-80': '#beecf7',
  'vis-turquoise-20': '#02665e',
  'vis-turquoise-30': '#008a83',
  'vis-turquoise-40': '#05b3b3',
  'vis-turquoise-50': '#43c4c4',
  'vis-turquoise-60': '#62cfd0',
  'vis-turquoise-70': '#7ee6e6',
  'vis-turquoise-80': '#c0f0f0',
  'vis-teal-20': '#006e56',
  'vis-teal-30': '#038f79',
  'vis-teal-40': '#07b8a9',
  'vis-teal-50': '#48c7bd',
  'vis-teal-60': '#62d0c7',
  'vis-teal-70': '#80e0d9',
  'vis-teal-80': '#c1ede9',
  'vis-mint-20': '#0d7532',
  'vis-mint-30': '#13945d',
  'vis-mint-40': '#22bd7e',
  'vis-mint-50': '#55c99b',
  'vis-mint-60': '#6cd1a8',
  'vis-mint-70': '#8dddbc',
  'vis-mint-80': '#c7eddd',
  'vis-green-20': '#307300',
  'vis-green-30': '#358f4b',
  'vis-green-40': '#4db362',
  'vis-green-50': '#72c488',
  'vis-green-60': '#80d095',
  'vis-green-70': '#94e3a8',
  'vis-green-80': '#c1f2cd',
  'vis-mantis-20': '#476b18',
  'vis-mantis-30': '#588c23',
  'vis-mantis-40': '#6db03e',
  'vis-mantis-50': '#8ec269',
  'vis-mantis-60': '#9fce7e',
  'vis-mantis-70': '#b2e092',
  'vis-mantis-80': '#d5edc3',
  'vis-olive-20': '#616116',
  'vis-olive-30': '#7b8012',
  'vis-olive-40': '#9aa619',
  'vis-olive-50': '#b7c249',
  'vis-olive-60': '#c0ca58',
  'vis-olive-70': '#d3de6f',
  'vis-olive-80': '#e9edc3',
  'vis-mustard-20': '#6e5d00',
  'vis-mustard-30': '#948200',
  'vis-mustard-40': '#b5a800',
  'vis-mustard-50': '#cfcf0a',
  'vis-mustard-60': '#d6d643',
  'vis-mustard-70': '#e0e05e',
  'vis-mustard-80': '#f2f2a5',

  'dm-blue': '#6699ff',
  'dm-blue-dark': '#273553',
  'dm-sky': '#dfe8fa',
  'dm-sky-darkest': '#5d7cbc',
  'dm-white': '#fafafa',
  'dm-gray-light': '#e8e8e8',
  'dm-gray': '#A8A8AC',
  'dm-gray-dark': '#4A4A4F',
  'dm-gray-darker': '#3E3E42',
  'dm-gray-darkest': '#2A2A2D',
  'dm-black': '#212123',
  'dm-black-dark': '#131315',
  'dm-black-darkest': '#000000',
  'dm-red-light': '#fed9db',
  'dm-red': '#ff667d',
  'dm-red-dark': '#450811',
  'dm-green-light': '#d7efdc',
  'dm-green': '#14b856',
  'dm-green-dark': '#0f7134',
  'dm-yellow-light': '#fff2c0',
  'dm-yellow': '#ffdd66',
  'dm-yellow-dark': '#4b3c09',
  'dm-orange-light': '#ffede6',
  'dm-orange': '#ff9466',
  'dm-orange-dark': '#4c1701',
  'dm-paywall-base': '#b5a1e5',
  'dm-paywall-dark': '#221728',
} as const;

export const ALL_PALETTE_COLOR_NAMES = Object.keys(PALETTE);

export const PRIMARY_PALETTE_COLOR_NAMES = [
  'blue-darkest',
  'blue-dark',
  'blue',
  'sky-darkest',
  'sky-dark',
  'sky',
  'black',
  'gray-dark',
  'gray',
  'gray-light',
  'gray-lightest',
  'white',
  'transparent',
  'off-white',
] as const;
export const SECONDARY_PALETTE_COLOR_NAMES = [
  'green-dark',
  'green',
  'green-light',
  'red-dark',
  'red',
  'red-light',
  'yellow-dark',
  'yellow',
  'yellow-light',
  'orange-dark',
  'orange',
  'orange-light',
  'paywall-base',
  'paywall-light',
  'paywall-dark',
] as const;
export const BORDERS_AND_SHADOWS_PALETTE_COLOR_NAMES = ALL_PALETTE_COLOR_NAMES.filter((colorName) =>
  colorName.startsWith('border-shadow'),
);
export const VISUALIZATION_PALETTE_COLOR_NAMES = ALL_PALETTE_COLOR_NAMES.filter((colorName) =>
  colorName.startsWith('vis-'),
);
export const DARK_MODE_PALETTE_COLOR_NAMES = ALL_PALETTE_COLOR_NAMES.filter((colorName) =>
  colorName.startsWith('dm-'),
);

const EXPORTED_PALETTE_NAMES = [
  ...PRIMARY_PALETTE_COLOR_NAMES,
  ...SECONDARY_PALETTE_COLOR_NAMES,
  ...BORDERS_AND_SHADOWS_PALETTE_COLOR_NAMES,
  ...VISUALIZATION_PALETTE_COLOR_NAMES,
  ...DARK_MODE_PALETTE_COLOR_NAMES,
] as const;

EXPORTED_PALETTE_NAMES.forEach((colorName) => {
  if (ALL_PALETTE_COLOR_NAMES.indexOf(colorName) === -1) {
    throw new Error(
      "A color name is exported in a sub-palette that doesn't exist in the main palette.",
    );
  }
});

if (EXPORTED_PALETTE_NAMES.length < ALL_PALETTE_COLOR_NAMES.length) {
  throw new Error('Not all color names are exported.');
}

export default PALETTE;
