import { isPresent } from '@ember/utils';
import { equal, notEmpty, and, empty, gt, filterBy, setDiff, not } from '@ember/object/computed';
import EmberObject, { computed } from '@ember/object';
import { isArray, A } from '@ember/array';
import { applyFunction, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import latinize from 'latinize';
import IcDropdownHeading from '@intercom/pulse/components/ic-dropdown-heading';

function itemIsGroup(item) {
  return isArray(item.items);
}

const HIGH_NUMBER_OF_ITEMS = 20;
const TRAILING_DOTS_REGEX = /(\.+)$|…$/;

const DropdownGroup = EmberObject.extend({
  isGroup: true,
  isActuallyStandard: computed('isFilter', 'isStandard', function () {
    let p = this.getProperties('isFilter', 'isStandard');
    let isActuallyStandard = !p.isFilter && (p.isStandard === undefined || p.isStandard);
    return isActuallyStandard;
  }),
  defaultFilterText: '',
  selectedCountLimit: null,
  filterTextIsUndefined: equal('filterText', undefined),
  rawFilterTextToUse: ternaryToProperty('filterTextIsUndefined', 'defaultFilterText', 'filterText'),
  filterTextToUse: applyFunction(latinize, 'rawFilterTextToUse'),
  filterTextIsNotEmpty: notEmpty('filterTextToUse'),
  isFiltering: and('filterTextIsNotEmpty', 'isFilterable'),
  noItemsToDisplay: empty('itemsToDisplay'),
  hasHighNumberOfItems: gt('itemsToDisplay.length', HIGH_NUMBER_OF_ITEMS),
  shouldUseVerticalCollection: and('isMain', 'hasHighNumberOfItems'),
  isMainGroupAndFilteringAndNoItemsToDisplay: and('isMain', 'noItemsToDisplay', 'isFiltering'),
  itemsThatAreGroups: filterBy('items', 'isGroup', true),
  itemsThatAreNotGroups: setDiff('items', 'itemsThatAreGroups'),
  unserializedItemsToDisplay: ternaryToProperty('isFilterable', 'filteredItems', 'items'),
  itemsToDisplay: ternaryToProperty(
    'isMain',
    'serializedItemsToDisplay',
    'unserializedItemsToDisplay',
  ),
  filteredItems: computed('filterTextToUse', 'items.[]', function () {
    let items = this.get('items');
    let downcasedFilterText = this.get('filterTextToUse').toLowerCase();
    if (items === undefined) {
      return A();
    }
    return items.filter((item) => {
      if (item.get('isGroup')) {
        return item.get('hasFilteredItems');
      } else {
        let text = latinize(item.get('text'));
        return typeof text !== 'string' || text.toLowerCase().includes(downcasedFilterText);
      }
    });
  }),
  workOverGroup(fn) {
    this.get('itemsThatAreGroups').forEach((group) => group.workOverGroup(fn));
    this.get('itemsThatAreNotGroups').forEach((item) => fn(item));
  },
  findInGroup(fn) {
    let foundItem = this.get('itemsThatAreNotGroups').find((item) => fn(item));
    if (foundItem !== undefined) {
      return foundItem;
    }
    let subGroups = this.get('itemsThatAreGroups');
    for (let i = 0, l = subGroups.length; i < l; i++) {
      foundItem = subGroups[i].findInGroup(fn);
      if (foundItem !== undefined) {
        return foundItem;
      }
    }
  },
  findSelectedItem() {
    return this.findInGroup((item) => item.get('isSelected'));
  },
  findItemByValue(value) {
    return this.findInGroup((item) => item.get('value') === value);
  },
  setSelectedItemByValue(value) {
    this.workOverGroup((item) => {
      let itemValueMatchesProvidedValue = item.get('value') === value;

      // avoid unnecessary `set`s in a loop
      if (itemValueMatchesProvidedValue !== item.isSelected) {
        item.set('isSelected', itemValueMatchesProvidedValue);
      }
      if (itemValueMatchesProvidedValue) {
        this.set('foundSelectedItem', item);
      }
    });
    this.notifyPropertyChange('selectedItems');
  },
  setLimitDisabledOnGroupItems() {
    let selectedItems = this.get('selectedItems');
    let selectedCountLimit = this.get('selectedCountLimit');
    let isSelectedCountLimitReached = selectedItems.length >= selectedCountLimit;

    this.workOverGroup((item) => {
      if (selectedCountLimit) {
        item.set('isLimitDisabled', !item.get('isSelected') && isSelectedCountLimitReached);
      }
    });
  },
  toggleSelectItemByValue(value) {
    this.workOverGroup((item) => {
      if (item.get('value') === value) {
        item.set('isSelected', !item.get('isSelected'));
      }
    });

    this.notifyPropertyChange('selectedItems');
    this.setLimitDisabledOnGroupItems();
  },
  selectedItem: computed('selectedValue', 'foundSelectedItem', function () {
    let selectedItem;
    let p = this.getProperties('selectedValue', 'foundSelectedItem');
    if (p.selectedValue !== undefined) {
      if (p.foundSelectedItem !== undefined && p.foundSelectedItem.value === p.selectedValue) {
        selectedItem = p.foundSelectedItem;
      } else {
        selectedItem = this.findItemByValue(p.selectedValue);
      }
    } else if (p.foundSelectedItem !== undefined) {
      selectedItem = p.foundSelectedItem;
    } else {
      selectedItem = this.findSelectedItem();
    }
    return selectedItem;
  }),
  selectedItems: computed('items.@each.isSelected', function () {
    let selectedItems = [];

    this.workOverGroup((item) => {
      if (item.get('isSelected')) {
        selectedItems.push(item);
      }
    });

    return selectedItems;
  }),
  hasNoFilteredItems: empty('filteredItems'),
  hasFilteredItems: not('hasNoFilteredItems'),
  setAllFilterTexts(filterText) {
    this.set('filterText', filterText);
    this.get('itemsThatAreGroups').forEach((group) => group.setAllFilterTexts(filterText));
    this.refreshSerializedItemsToDisplay();
  },
  refreshSerializedItemsToDisplay() {
    let serializedItemsToDisplay = [];
    let unserializedItemsToDisplay = this.get('unserializedItemsToDisplay');
    if (unserializedItemsToDisplay !== undefined) {
      unserializedItemsToDisplay.forEach((item, groupIndex) => {
        let nestedItemsToDisplay = item.get('serializedItemsToDisplay');
        if (nestedItemsToDisplay === undefined) {
          serializedItemsToDisplay.push(item);
        } else {
          if (isPresent(item.headingComponent)) {
            serializedItemsToDisplay.push(
              createHeadingComponentObject(
                item.headingComponent,
                item.headingComponentAttrs,
                groupIndex,
              ),
            );
          } else if (isPresent(item.heading)) {
            serializedItemsToDisplay.push(
              createHeadingObject(item.heading, groupIndex, item.stamp),
            );
          }
          nestedItemsToDisplay.forEach((nestedItem) => {
            serializedItemsToDisplay.push(nestedItem);
          });
        }
      });
    }
    this.set('serializedItemsToDisplay', A(serializedItemsToDisplay));
  },
});

function cleanPlaceholder(placeholder) {
  if (placeholder === undefined) {
    return;
  }
  return `${placeholder.replace(TRAILING_DOTS_REGEX, '')}…`;
}

function createHeadingComponentObject(component, componentAttrs) {
  return EmberObject.create({
    component,
    componentAttrs,
    componentShouldReplaceItem: true,
  });
}

function createHeadingObject(heading, groupIndex, stamp) {
  return EmberObject.create({
    component: IcDropdownHeading,
    componentShouldReplaceItem: true,
    text: heading,
    groupIndex,
    stamp,
  });
}

function createDropdownGroupItem(item, containingGroupList) {
  return EmberObject.create(
    Object.assign({}, item, {
      isSelected: containingGroupList['hasItemsMarkableAsSelected']
        ? !!item['isSelected']
        : item['isSelected'],
      canBeMarkedAsSelected: containingGroupList['hasItemsMarkableAsSelected'],
      placeholderToUse: cleanPlaceholder(item.placeholder),
    }),
  );
}

export function createDropdownGroupFromGroupList(groupList, index = 0, isFilterable) {
  if (groupList === undefined) {
    return DropdownGroup.create({});
  }

  let groupListWithoutItems;
  let itemsArray;

  // Adjust for top-level
  if (isArray(groupList)) {
    groupListWithoutItems = {};
    itemsArray = groupList;
  } else {
    groupListWithoutItems = Object.assign({ index }, groupList);
    delete groupListWithoutItems.items;
    itemsArray = groupList.items;
    // Infer isFilterable from groupList if not provided
    isFilterable = isFilterable === undefined ? !!groupListWithoutItems.isFilterable : isFilterable;
  }

  let group = DropdownGroup.create(groupListWithoutItems);
  group.set('isFilterable', isFilterable);

  // Add items to dropdownGroup
  let groupItems = [];
  if (isArray(itemsArray)) {
    itemsArray.forEach((item, itemIndex) => {
      if (itemIsGroup(item)) {
        groupItems.push(createDropdownGroupFromGroupList(item, itemIndex, isFilterable));
      } else {
        groupItems.push(createDropdownGroupItem(item, groupList));
      }
    });
  }

  group.set('items', A(groupItems));
  group.setLimitDisabledOnGroupItems();
  group.refreshSerializedItemsToDisplay();
  return group;
}

export default DropdownGroup;
