import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Args {
  secret: string;
}

export default class SensitiveText extends Component<Args> {
  @tracked isVisible = false;
  placeholderMask = '****************';
}
