import Component from '@glimmer/component';
import { assert } from '@ember/debug';

const STAMP_COLOR_MAP = {
  blue: {
    background: 'bg-blue',
    border: 'border-blue',
    text: 'text-white',
  },
  sky: {
    background: 'bg-sky',
    border: 'border-sky',
    text: 'text-blue',
  },
  transparent: {
    background: 'bg-transparent',
    border: 'border-shadow-dark',
    text: 'text-gray',
  },
  green: {
    background: 'bg-green-light',
    border: 'border-green-light',
    text: 'text-green-dark',
  },
  gray: {
    background: 'bg-gray-light',
    border: 'border-gray-light',
    text: 'text-gray',
  },
  'yellow-light': {
    background: 'bg-yellow-light',
    border: 'border-yellow-light',
    text: 'text-yellow-dark',
  },
  yellow: {
    background: 'bg-yellow',
    border: 'border-yellow',
    text: 'text-yellow-dark',
  },
  red: {
    background: 'bg-red-light',
    border: 'border-red-light',
    text: 'text-red-dark',
  },
};

const DEFAULT_OPTION_CLASS = STAMP_COLOR_MAP['sky'];

export default class StampComponent extends Component {
  get color() {
    if (this.args.color) {
      let color = this.args.color.toLowerCase();
      if (!STAMP_COLOR_MAP[color]) {
        assert(
          `<Pulse::Stamp>: The color \`${
            this.args.color
          }\` is invalid. Select from these valid options: ${Object.keys(STAMP_COLOR_MAP).join(
            ', ',
          )}`,
        );
      }
      return STAMP_COLOR_MAP[color];
    }
    return DEFAULT_OPTION_CLASS;
  }
}
