import { equal, notEmpty, not, and, reads } from '@ember/object/computed';
import { computed } from '@ember/object';
import defaultTo from '@intercom/pulse/lib/default-to';
import ICBaseComponent from './ic-base-component';

let ICON_MAP = {
  default: 'notification',
  confirmation: 'rounded-check',
  warning: 'alert-circle',
  error: 'error',
  help: 'info',
  loading: 'loading',
};

export default ICBaseComponent.extend({
  attributeBindings: ['data-test-banner'],
  'data-test-banner': true,
  ariaRole: computed('type', function () {
    let type = this.get('type');
    return type === 'error' || type === 'warning' ? 'alert' : 'status';
  }),
  componentClasses: computed('bannerOptionClass', 'showButton', 'isFloating', function () {
    let classes = ['banner', this.get('bannerOptionClass')];
    if (this.get('isFloating')) {
      classes.push('o__floating');
    }
    classes.push('flex');
    if (this.get('showButton')) {
      classes.push('items-center');
    } else {
      classes.push('items-start');
    }
    return classes.join(' ');
  }),
  isLoadingType: equal('type', 'loading'),
  iconMap: ICON_MAP,
  icon: computed('type', function () {
    return ICON_MAP[this.get('type')];
  }),
  showIcon: defaultTo(true),
  bannerOptionClass: computed('type', function () {
    return `o__${this.get('type')}`;
  }),
  showHide: notEmpty('onHideClick'),
  dontShowHide: not('showHide'),
  onButtonClickProvided: notEmpty('onButtonClick'),
  showButton: and('dontShowHide', 'onButtonClickProvided'),
  floating: false,
  isFloating: reads('floating'),
  isNotFloating: not('isFloating'),
  actions: {
    hideClick() {
      this.get('onHideClick')();
    },
    buttonClick() {
      this.get('onButtonClick')();
    },
  },
});
