import { helper as buildHelper } from '@ember/component/helper';
import { guidFor } from '@ember/object/internals';
import { inlineSvg } from 'ember-inline-svg/helpers/inline-svg';
import { get } from '@ember/object';
import avatarUtils from '@intercom/pulse/lib/avatar-utils';
import SVGs from '@intercom/pulse/svgs';

const MAX_NUMBER_OF_FAILED_AVATAR_URLS_TO_TRACK = 100;
const domParser = new DOMParser();

let failedAvatarURLs = new Map();

function isKnownFailedURL(url) {
  return failedAvatarURLs.get(url) === true;
}

function addFailedAvatarURL(url) {
  if (!isKnownFailedURL(url)) {
    failedAvatarURLs.set(url, true);
    if (failedAvatarURLs.size > MAX_NUMBER_OF_FAILED_AVATAR_URLS_TO_TRACK) {
      failedAvatarURLs.delete(failedAvatarURLs.entries().next().value[0]);
    }
  }
}

function sizedNodeWithClass(size, className) {
  let node = document.createElement('SPAN');
  node.classList.add(className);
  node.classList.add(`o__${size}`);
  return node;
}

function avatarTemplate(size, contained, deleted, withText, isTeam) {
  let node = sizedNodeWithClass(size, 'avatar');
  if (contained) {
    node.classList.add('o__contained');
  } else if (withText) {
    node.classList.add('o__with-text');
  }
  if (deleted) {
    node.classList.add('o__for-deleted-object');
  }
  if (isTeam) {
    node.classList.add('o__for-team');
  }
  return node;
}

function activeIndicatorNode(size, avatarData, onBackgroundColor) {
  let node = sizedNodeWithClass(size, 'avatar__badge');
  if (avatarData.showAsActive || avatarData.showAsAway) {
    node.classList.add('o__on');
    if (avatarData.showAsAway) {
      node.classList.add('o__away');
    } else if (avatarData.showAsActive) {
      node.classList.add('o__active');
    }
  }
  if (onBackgroundColor) {
    node.classList.add(`o__on-background-palette-${onBackgroundColor}`);
  }
  return node;
}

function createColorAvatarMediaNode(color) {
  let mediaNode = document.createElement('SPAN');
  mediaNode.classList.add('avatar__media');
  mediaNode.style.backgroundColor = `#${color}`;
  return mediaNode;
}

function textAvatarMediaNode(initials, color, shouldAnimateUpdate, avatarData) {
  let mediaNode = createColorAvatarMediaNode(color);
  if (avatarData?.nameOrEmail) {
    mediaNode.setAttribute('role', 'img');
    mediaNode.setAttribute('aria-label', avatarData.nameOrEmail);
  }
  if (shouldAnimateUpdate) {
    mediaNode.classList.add('a__fade-in');
  }
  let textNode = document.createTextNode(initials);
  mediaNode.appendChild(textNode);
  return mediaNode;
}

function companyAvatarMediaNode(color, size, svgLibrary) {
  let mediaNode = createColorAvatarMediaNode(color);
  let svgHTML = inlineSvg(svgLibrary, avatarUtils.avatarIconPath(size), {
    class: 'avatar__media__icon',
  });
  let svgNode = domParser.parseFromString(svgHTML, 'text/xml').firstChild;
  mediaNode.appendChild(svgNode);
  return mediaNode;
}

function fallbackNodeForAvatar(avatarData, options) {
  if (avatarData.isCustomer && typeof avatarData.initials === 'string') {
    return textAvatarMediaNode(
      avatarData.initials,
      avatarData.color,
      avatarData.shouldAnimateUpdate,
      avatarData,
    );
  } else {
    return imageAvatarMediaNode(avatarUtils.avatarFallbackUrl(avatarData, options));
  }
}

function imageAvatarMediaNode(avatarImageUrl, avatarData, options) {
  let mediaNode = document.createElement('IMG');
  mediaNode.classList.add('avatar__media');
  mediaNode.setAttribute('src', avatarImageUrl);
  if (avatarData?.nameOrEmail) {
    mediaNode.setAttribute('alt', avatarData.nameOrEmail);
  }
  if (avatarData !== undefined && options !== undefined) {
    // Set up fallback - not in a nice separate function due to jsHint going a bit nuts.
    // I've decided to keep this here, rather than disable that particular jsHint warning,
    // as I want avoid other latedef function warnings that might actually be legit.
    // See: http://jshint.com/docs/options/#latedef

    let mediaNodeId = guidFor(mediaNode);
    mediaNode.setAttribute('id', mediaNodeId);
    let replacementNode = fallbackNodeForAvatar(avatarData, options);

    // Check to see if image loads
    let img = new Image();
    img.onload = function () {
      if (this.naturalHeight + this.naturalWidth === 0) {
        return this.onerror();
      }
      img = null;
      replacementNode = null;
    };
    img.onerror = function () {
      let node = document.getElementById(mediaNodeId);
      if (node !== null) {
        addFailedAvatarURL(avatarImageUrl);
        node.parentNode.replaceChild(replacementNode, node);
      }
    };
    img.src = avatarImageUrl;
  }
  return mediaNode;
}

function avatarNode(avatarData, options, avatarTypeToRender, svgLibrary) {
  let node = avatarTemplate(
    options.size,
    options.contained,
    avatarData.deleted,
    options.withText,
    avatarData.isTeam,
  );
  let mediaNode;
  if (avatarTypeToRender === 'text') {
    mediaNode = textAvatarMediaNode(
      avatarData.initials,
      avatarData.color,
      avatarData.shouldAnimateUpdate,
      avatarData,
    );
  } else if (avatarTypeToRender === 'company') {
    mediaNode = companyAvatarMediaNode(avatarData.color, options.size, svgLibrary);
  } else if (isKnownFailedURL(avatarData.url)) {
    mediaNode = fallbackNodeForAvatar(avatarData, options);
  } else {
    mediaNode = imageAvatarMediaNode(avatarData.url, avatarData, options);
  }
  if (avatarData.isTeam) {
    mediaNode.classList.add('o__for-team');
  }
  let shapeClass = avatarUtils.avatarShapeClass(avatarData.avatarShape);
  if (shapeClass) {
    mediaNode.classList.add(shapeClass);
  }
  node.appendChild(mediaNode);
  if (avatarUtils.shouldRenderAvatarBadge(avatarData, options.displayAdminBadge)) {
    node.appendChild(activeIndicatorNode(options.size, avatarData, options.onBackgroundColor));
  }
  return node;
}

function _getModelName(model) {
  if (!model) {
    return;
  }
  if (get(model, 'nameOrEmail')) {
    return get(model, 'nameOrEmail');
  }
  if (get(model, 'name')) {
    return get(model, 'name');
  }
}

export function icAvatar(params, hash, svgLibrary = SVGs) {
  let originalAvatarData;
  if (hash['model'] !== undefined) {
    let modelAvatarData = get(hash['model'], 'avatarData');
    if (modelAvatarData) {
      originalAvatarData = Object.assign(
        {
          nameOrEmail: _getModelName(hash['model']),
        },
        modelAvatarData,
      );
    }
  } else {
    originalAvatarData = hash['direct'];
  }

  let options = avatarUtils.formatOptions(hash);
  let avatarData = avatarUtils.checkAvatarData(originalAvatarData, options);
  let avatarTypeToRender = avatarUtils.checkAvatarTypeToRender(avatarData, options);

  return avatarNode(avatarData, options, avatarTypeToRender, svgLibrary);
}

export default buildHelper(icAvatar);
