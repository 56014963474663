import { helper as buildHelper } from '@ember/component/helper';

// This helper essentially behaves the same as the built-in `get` helper
// https://www.emberjs.com/api/ember/release/classes/Ember.Templates.helpers/methods/get?anchor=get
// in that it uses the second argument (a string) as a key to index into the
// first argument (a hash) and return the corresponding property at that key.
// The only difference being that the built-in `get` helper treats periods in
// the string as nesting boundaries:
//
//   let foo = {
//     bar: {
//       baz: 22
//     }
//   }
//
//   {{get foo 'bar.baz'}} // renders 22
//
//  however it is syntactically valid for javascript object keys to contain
//  periods. This means that it becomes impossible for the built-in `get`
//  helper to resolve:
//
//   let foo = {
//     ['bar.baz']: 22
//   }
//
//   as it will look for:
//     `baz` in `foo.bar`
//   instead of looking for:
//     `bar.baz` in `foo`
//
//   this helper does none of the fancy `String.split` processing that its
//   counterpart does but rather simply passes the second argument directly
//   as the lookup key for the first argument.
export function getWithLiteralKey([obj, key], _options) {
  if (!obj || typeof key !== 'string') {
    return;
  }
  return obj[key];
}

export default buildHelper(getWithLiteralKey);
