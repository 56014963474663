import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { HELP_CONTENT_EXPERIMENT_FF_NAME } from '@intercom/pulse/lib/temp-constants';

export default class PulseHelpContentLink extends Component {
  @service appService;
  @service intercomEventService;

  linkStyling = this.args.linkStyling || 'standalone';
  @tracked showVideoModal = false;

  get isDisabledByExperimentalFeatureFlag() {
    return (
      this.linkStyling === 'standalone' &&
      this.appService?.app?.canUseFeature(HELP_CONTENT_EXPERIMENT_FF_NAME)
    );
  }

  @action
  handleClick(event) {
    event.preventDefault();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'help_content_link',
      context: this.linkStyling,
      content_type: 'video',
      link_to: this.args.wistiaId,
    });
    this.showVideoModal = true;
  }

  @action
  onModalClose() {
    this.args.onVideoClose?.();
    this.showVideoModal = false;
  }
}
