import keycodes from '@intercom/pulse/lib/keynames';
import Modifier from 'ember-modifier';
import { next } from '@ember/runloop';
import { addEventListener } from 'ember-lifeline';

export default class TrapFocusModifier extends Modifier {
  defaultTabbableElements = 'button, textarea, input, select';

  constructor() {
    super(...arguments);
    this.initialFocus = this.options.initialFocus || this.focusFirstFocusableElement;
    this.tabbableElements = this.options.tabbableElements || this.defaultTabbableElements;
    this.handleTab = this.options.handleTab || this.defaultHandleTab;
  }

  didInstall() {
    addEventListener(this, this.element, 'keydown', this.handleKeydown);
    this.initialFocus(this.element);
  }

  get options() {
    return this.args.named;
  }

  handleKeydown(event) {
    switch (event.keyCode) {
      case keycodes.tab:
        this.handleTab(event);
        break;
    }
  }

  focusFirstFocusableElement(element) {
    next(this, function () {
      let focusableElements = Array.from(element.querySelectorAll(this.tabbableElements));
      let elementsWithoutClose = focusableElements.filter(
        (el) => !/^[Cc]lose$/.test(el.getAttribute('aria-label')),
      );
      if (!elementsWithoutClose.length) {
        this.element.focus();
      } else {
        elementsWithoutClose[0].focus();
      }
    });
  }

  defaultHandleTab(event) {
    let focusableElements = this.element.querySelectorAll(this.tabbableElements);
    let firstFocusable = focusableElements[0];
    let lastFocusable = focusableElements[focusableElements.length - 1];
    if (event.shiftKey) {
      // Shift + Tab
      if (document.activeElement === firstFocusable) {
        lastFocusable.focus();
        event.preventDefault();
      }
    } else if (document.activeElement === lastFocusable) {
      // Tab
      firstFocusable.focus();
      event.preventDefault();
    }
  }
}
