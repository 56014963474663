import Component from '@ember/component';
import { computed } from '@ember/object';
import { and, or } from '@ember/object/computed';
import { guidFor } from '@ember/object/internals';

export default Component.extend({
  tagName: '',

  inputId: computed(function () {
    return `${guidFor(this)}-input`;
  }),
  actions: {
    onBlur() {
      this.set('hasBlurred', true);
    },
  },
  isInvalid: false,
  hasBlurred: false,
  canShowError: or('hasSubmittedForm', 'hasBlurred'),
  showError: and('canShowError', 'isInvalid'),
});
