import { computed, get } from '@ember/object';

export default function joinBy(dependentKey, propertyKey, separator) {
  if (typeof separator === 'undefined') {
    separator = ',';
  }

  return computed(`${dependentKey}.@each.${propertyKey}`, function () {
    return get(this, dependentKey).mapBy(propertyKey).join(separator);
  });
}
