import Modifier from 'ember-modifier';
import { inject as service } from '@ember/service';
import ClipboardJS from 'clipboard';

export default class CopyToClipboardModifier extends Modifier {
  @service notificationsService;
  @service intl;

  clipboard = null;

  get text() {
    return this.args.named.text;
  }

  get onSuccess() {
    return this.args.named.onSuccess || (() => {});
  }

  get onFailure() {
    return this.args.named.onFailure || (() => {});
  }

  get confirmationMessage() {
    return this.args.named.confirmationMessage || this.intl.t('pulse.copy-to-clipboard.success');
  }

  get errorMessage() {
    return this.args.named.errorMessage || this.intl.t('pulse.copy-to-clipboard.error');
  }

  addEventListener() {
    let onSuccessCallback = () => {
      this.notificationsService?.notifyConfirmation(`${this.confirmationMessage}`);
      this.onSuccess();
    };
    let onErrorCallback = () => {
      this.notificationsService?.notifyWarning(this.errorMessage);
      this.onFailure();
    };
    this.clipboard = this.createClipboard(
      this.element,
      this.text,
      onSuccessCallback,
      onErrorCallback,
    );
  }

  removeEventListener() {
    this.clipboard?.destroy();
  }

  didReceiveArguments() {
    this.removeEventListener();
    this.addEventListener();
  }

  willRemove() {
    this.removeEventListener();
  }

  createClipboard(element, textToCopy, onSuccess, onError) {
    let clipboard = new ClipboardJS(element, { text: () => textToCopy });

    if (onSuccess) {
      clipboard.on('success', onSuccess);
    }

    if (onError) {
      clipboard.on('error', onError);
    }

    return clipboard;
  }
}
