import { helper as buildHelper } from '@ember/component/helper';

function tableCellNode(value, widthToRender, dummyColumn, usesLinkHelper) {
  let cellNode = document.createElement('TD');
  let widthDiv = document.createElement('DIV');
  let measureDiv = document.createElement('DIV');
  cellNode.classList.add('table__cell');
  widthDiv.classList.add('table__cell__jammer');
  measureDiv.classList.add('table__cell__measure__element');
  if (!dummyColumn) {
    if (usesLinkHelper) {
      let link = document.createElement('A');
      link.setAttribute('href', value.url);
      let textNode = document.createTextNode(value.text);
      link.appendChild(textNode);
      measureDiv.appendChild(link);
    } else {
      measureDiv.appendChild(document.createTextNode(value));
    }
  }
  widthDiv.appendChild(measureDiv);
  if (widthToRender !== undefined) {
    widthDiv.style.minWidth = `${widthToRender}px`;
  }
  cellNode.appendChild(widthDiv);
  return cellNode;
}

export function icLegacyTableCellHelper(params, hash) {
  let column = hash['column'];
  let row = hash['row'];
  let value = row[column.propertyName];
  let usesLinkHelper = column.usesLinkHelper;
  return tableCellNode(value, column.widthToRender, column.dummy, usesLinkHelper);
}

export default buildHelper(icLegacyTableCellHelper);
