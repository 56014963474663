import { computed, get } from '@ember/object';
import emberStringFmt from 'ember-string-fmt';

const slice = Array.prototype.slice;

export default function fmt() {
  let params = slice.call(arguments);
  let template = params.shift();
  let keys = params.slice(0);

  params.push(function () {
    let values = [];
    for (let i = 0; i < keys.length; ++i) {
      values.push(get(this, keys[i]));
    }
    return emberStringFmt(template, values);
  });

  return computed.apply(this, params);
}
