import { resolve } from 'rsvp';
import { computed } from '@ember/object';
import { reads, notEmpty, and, none, not, or } from '@ember/object/computed';
import { buttonClassesMetal } from '@intercom/pulse/helpers/button-classes-metal';
import { hash, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import ICBaseComponent from './ic-base-component';

export default ICBaseComponent.extend({
  tagName: 'button',
  ariaRole: 'button',
  attributeBindings: ['isDisabled:disabled', 'htmlTypeAttribute:type', 'data-test-ic-button'],
  htmlTypeAttribute: 'button',
  type: 'secondary',
  isDisabled: reads('disabled'),
  isSaving: reads('saving'),
  isTruncated: reads('truncated'),
  isSubtle: reads('subtle'),
  isForcedActive: reads('forcedActive'),
  disabled: false,
  inCell: false,
  isProcessing: false,
  truncated: false,
  hasProcessingLabel: notEmpty('processingLabel'),
  hasProcessingLabelAndIsProcessing: and('hasProcessingLabel', 'isProcessing'),
  labelToDisplay: ternaryToProperty(
    'hasProcessingLabelAndIsProcessing',
    'processingLabel',
    'label',
  ),
  onClick: () => {},
  saving: reads('isProcessing'),
  componentClasses: computed(
    '_buttonListIsProcessing',
    'type',
    'inCell',
    'isDisabled',
    'size',
    'iconOnlyIsImpliedOrExplicit',
    'inRightList',
    'inLeftList',
    'fit',
    'isSaving',
    'isForcedActive',
    'isSubtle',
    'isDropdownOpener',
    'isTruncated',
    'hasError',
    function () {
      let properties = this.getProperties(
        'type',
        'inCell',
        'isDisabled',
        'size',
        'inRightList',
        'inLeftList',
        'fit',
        'isSaving',
        'isForcedActive',
        'isSubtle',
        'isDropdownOpener',
        'isTruncated',
        'hasError',
      );
      properties.showsIconOnly = this.get('iconOnlyIsImpliedOrExplicit');
      if (this.get('_buttonListIsProcessing')) {
        properties.isDisabled = !properties.isSaving;
      }
      return buttonClassesMetal(properties).join(' ');
    },
  ),
  labelUndefined: none('label'),
  iconUndefined: none('icon'),
  iconPresent: not('iconUndefined'),
  iconOnlyIsImplied: and('iconPresent', 'labelUndefined'),
  iconOnlyIsImpliedOrExplicit: or('showsIconOnly', 'iconOnlyIsImplied'),
  publicAPI: hash('label', 'isProcessing'),
  onClickHandling(event) {
    this.set('isProcessing', true);
    let onClickReturnVal = this.get('onClick')(event);
    resolve(onClickReturnVal).finally(() => {
      if (!this.isDestroying) {
        this.set('isProcessing', false);
      }
    });
  },
  click(event) {
    let p = this.getProperties('isDisabled', 'isSaving', 'isProcessing');
    if (!(p.isDisabled || p.isProcessing || p.isSaving)) {
      this.onClickHandling(event);
    }
  },
});
