let shuffle = function <T>(array: T[]) {
  if (array instanceof Array) {
    for (let index = array.length - 1; index > 0; index--) {
      let swapIndex = Math.floor(Math.random() * (index + 1));
      let temp = array[index];
      array[index] = array[swapIndex] as T;
      array[swapIndex] = temp as T;
    }
    return array;
  } else {
    throw new Error('Input was not an array.');
  }
};

export default shuffle;
