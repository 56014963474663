import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { assert, runInDebug } from '@ember/debug';
import { task } from 'ember-concurrency-decorators';

const VALID_DISMISS_TYPES = ['close-icon', 'got-it-button'];

export default class PulseDiscoveryBanner extends Component {
  @service intercomEventService;
  @tracked shouldShowBanner = false;

  constructor() {
    super(...arguments);
    this.computeVisibility.perform();
  }

  get canBeDismissed() {
    let hasProvidedDismissConfig =
      this.args.dismissalType || this.args.dismissalKey || this.args.dismissalService;

    if (hasProvidedDismissConfig) {
      let validdismissalTypeProvided = VALID_DISMISS_TYPES.includes(this.args.dismissalType);
      let validDismissalKeyProvided = typeof this.args.dismissalKey === 'string';
      let validDismissMethodProvided = typeof this.args.dismissalService?.dismiss === 'function';
      let validHasDismissedMethodProvided =
        typeof this.args.dismissalService?.hasDismissed === 'function';
      runInDebug(() => {
        assert(
          `<Pulse::DiscoveryBanner>: @dismissalType must be one of ${JSON.stringify(
            VALID_DISMISS_TYPES,
          )}`,
          validdismissalTypeProvided,
        );
        assert(
          `<Pulse::DiscoveryBanner>: @dismissalKey must be provided`,
          validDismissalKeyProvided,
        );
        assert(
          `<Pulse::DiscoveryBanner>: the provided @dismissalService must expose the \`dismiss\` method`,
          validDismissMethodProvided,
        );
        assert(
          `<Pulse::DiscoveryBanner>: the provided @dismissalService must expose the \`hasDismissed\` method`,
          validHasDismissedMethodProvided,
        );
      });
      return Boolean(
        validdismissalTypeProvided &&
          validDismissalKeyProvided &&
          validDismissMethodProvided &&
          validHasDismissedMethodProvided,
      );
    } else {
      return false;
    }
  }

  @task
  *computeVisibility() {
    if (this.canBeDismissed) {
      let adminHasDismissedBanner = yield this.args.dismissalService.hasDismissed(
        this.args.dismissalKey,
      );
      if (!adminHasDismissedBanner) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'viewed',
          object: 'discovery-banner',
          context: this.args.dismissalKey,
        });
        this.shouldShowBanner = true;
      }
    } else {
      this.shouldShowBanner = true;
    }
  }

  @task
  *handleDismissClick() {
    this.shouldShowBanner = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'dismissed',
      object: 'discovery-banner',
      context: this.args.dismissalKey,
    });
    yield this.args.dismissalService.dismiss(this.args.dismissalKey);
    if (typeof this.args.onDismiss === 'function') {
      this.args.onDismiss();
    }
  }
}
