import { helper as buildHelper } from '@ember/component/helper';
import { assert, runInDebug } from '@ember/debug';

export function validateEnum(_positionalArgs, namedArgs) {
  runInDebug(() => {
    if (namedArgs.required) {
      assert(
        `<${namedArgs.component}>: "${
          namedArgs.name
        }" is a required option. Use one of the following supported values: ${JSON.stringify(
          namedArgs.supportedValues,
        )}`,
        namedArgs.value,
      );
    }

    if (namedArgs.value) {
      let hasProvidedDenyList = namedArgs.unsupportedValues;
      let hasProvidedAllowList = namedArgs.supportedValues;

      if (hasProvidedDenyList) {
        let providedValueIsInDenyList = namedArgs.unsupportedValues.includes(namedArgs.value);
        assert(
          `<${namedArgs.component}>: Provided value (${
            namedArgs.value
          }) may not be one of the unsupported values of "${namedArgs.name}": ${JSON.stringify(
            namedArgs.unsupportedValues,
          )}`,
          !providedValueIsInDenyList,
        );
      }

      if (hasProvidedAllowList) {
        let providedValueIsInAllowList = namedArgs.supportedValues.includes(namedArgs.value);
        assert(
          `<${namedArgs.component}>: Provided value (${
            namedArgs.value
          }) is not one of the supported values of "${namedArgs.name}": ${JSON.stringify(
            namedArgs.supportedValues,
          )}`,
          providedValueIsInAllowList,
        );
      }
    }
  });
}

export default buildHelper(validateEnum);
