import { helper as buildHelper } from '@ember/component/helper';
import { renderInterfaceIcon } from '../lib/svg-icons';

let domParser = new DOMParser();

export function icInterfaceIcon(params, options) {
  let iconSet = options['set'] === undefined ? 'standard' : options['set'];
  return renderInterfaceIcon(iconSet, params, options);
}

export function icInterfaceIconAsDomNode() {
  let iconHTML = icInterfaceIcon(...arguments);
  return domParser.parseFromString(iconHTML, 'text/xml').firstChild;
}

export default buildHelper(icInterfaceIcon);
