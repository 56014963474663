import Component from '@glimmer/component';

import { action } from '@ember/object';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default class ColorPicker extends Component {
  colorHexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  isColorValid(color) {
    return !!this.colorHexRegex.exec(color);
  }

  threeDigitHexToSix(color) {
    return color
      .split('')
      .map((digit) => `${digit}${digit}`)
      .join('');
  }

  @action
  handleChange(event) {
    let value = event.target.value;
    if (this.isColorValid(value)) {
      this.args.setColor?.(value);
    }
  }

  get backgroundColorStyle() {
    return sanitizeHtml(`background-color: ${this.args.value || '#222222'};`);
  }

  /**
   * ember-colpick tries to directly mutate input
   * The get/set here restrict that so it doesn't propogate to users of Form::ColorPicker
   * Instead changes ember-colpick makes still go through `setColor` rather than directly mutating
   */
  get colPickValue() {
    let color = this.args.value;
    if (this.isColorValid(color)) {
      return color.length === 4
        ? this.threeDigitHexToSix(color.replace('#', ''))
        : color.replace('#', '');
    }
    return '';
  }

  set colPickValue(value) {
    this.args.setColor?.(`#${value}`);
  }
}
