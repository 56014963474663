import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';

export default class PulseAccordion extends Component {
  @tracked isDisabled = false;
  previouslyOpenedSection = this.args.openSectionId;

  get groupId() {
    return `pulse-accordion-${guidFor(this)}`;
  }

  @action
  sectionDidUpdate(_element, [openSectionId]) {
    if (openSectionId && this.args.onSectionDidOpen) {
      this.args.onSectionDidOpen(openSectionId);
    }
    if (this.previouslyOpenedSection && this.args.onSectionDidClose) {
      this.args.onSectionDidClose(this.previouslyOpenedSection);
    }
    this.previouslyOpenedSection = openSectionId;
  }

  @action
  openSection(sectionId) {
    if (!this.args.isDisabled) {
      this.args.onOpenSectionChange(sectionId);
    }
  }

  @action
  closeSection() {
    this.args.onOpenSectionChange(null);
  }

  @action
  toggleSection(sectionId) {
    if (sectionId === this.args.openSectionId) {
      this.closeSection(sectionId);
    } else {
      this.openSection(sectionId);
    }
  }

  get accordionAPI() {
    return {
      openSectionId: this.args.openSectionId,
      isDisabled: this.args.isDisabled,
      openSection: (...args) => this.openSection(...args),
      closeSection: (...args) => this.closeSection(...args),
      toggleSection: (...args) => this.toggleSection(...args),
    };
  }
}
