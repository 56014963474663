import ICButton from '@intercom/pulse/components/ic-button';

// This subclass exists as a hack to add more conditional classes to our
// base button component as well as to be able to add the data-test attributes
export default ICButton.extend({
  attributeBindings: [
    'data-test-radio-button-group-button',
    'data-test-radio-button-group-button-selected',
  ],
  classNameBindings: ['fullWidth:flex-auto'],
  // This subclass doesn't render the button component's template
  // unless we add this layoutName
  // Ember open issue: https://github.com/emberjs/rfcs/issues/412
  layoutName: 'components/ic-button',
});
