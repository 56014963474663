import { computed } from '@ember/object';
import ICBaseComponent from './ic-base-component';

export default ICBaseComponent.extend({
  display: 'block',
  align: 'left',
  componentClasses: computed('display', 'align', function () {
    let classes = ['button__list'];
    let p = this.getProperties('display', 'align');
    if (p.display === 'block') {
      classes.push('o__block');
      classes.push(`o__${p.align}`);
    }
    return classes.join(' ');
  }),
});
