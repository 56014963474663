// This is an alternate helper for ember-inline-svg that hardcodes the
// SVGLibrary provided to their core svg code. We need to change the library
// because they default to a relative import (`../svgs`) that isn't compatible
// with embroider. Here we import the dist/svg.js generated by our rollup config

import { helper } from '@ember/component/helper';
import { inlineSvg } from 'ember-inline-svg/helpers/inline-svg';
import SVGs from '@intercom/pulse/svgs';

export default helper(function ([path], options) {
  return inlineSvg(SVGs, path, options);
});
