export const ASSET_MAP_PROPERTY_NAME_ON_WINDOW = '_internal_assetMap';
export const ASSET_MAP_STATUS_PROPERTY_NAME_ON_WINDOW = '_internal_assetMap_status';

export function initialize(/* application */) {
  if (window[ASSET_MAP_STATUS_PROPERTY_NAME_ON_WINDOW]) {
    return;
  }

  let assetMapMetaTag = document.querySelector('meta[name="broccoli-asset-map"]');
  if (assetMapMetaTag) {
    try {
      let assetMapJson = JSON.parse(decodeURI(assetMapMetaTag.content));
      window[ASSET_MAP_PROPERTY_NAME_ON_WINDOW] = assetMapJson;
      window[ASSET_MAP_STATUS_PROPERTY_NAME_ON_WINDOW] = 'success';
    } catch (e) {
      window[ASSET_MAP_STATUS_PROPERTY_NAME_ON_WINDOW] = 'error-decoding-meta-tag';
      console.error(
        'Broccoli assetMap decoding and parsing failed with meta tag contents:',
        assetMapMetaTag.content,
      );
    }
  } else {
    window[ASSET_MAP_STATUS_PROPERTY_NAME_ON_WINDOW] = 'missing-meta-tag';
  }
}

export default {
  initialize,
};
