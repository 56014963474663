import { computed, get } from '@ember/object';
import { isNone } from '@ember/utils';

export default function percentage(numeratorKey, denominatorKey) {
  return computed(numeratorKey, denominatorKey, function () {
    let numerator = get(this, numeratorKey);
    let denominator = get(this, denominatorKey);

    if (
      isNone(numerator) ||
      isNone(denominator) ||
      isNaN(numerator) ||
      isNaN(denominator) ||
      denominator === 0
    ) {
      return;
    }
    return (numerator / denominator) * 100;
  });
}
