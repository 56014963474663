import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class PulseAccordionSection extends Component {
  @tracked isHovered = false;
  localId = this.args.id;
  globalId = `${this.args.groupId}${this.args.id}`;

  get animate() {
    return this.args.animate ?? true;
  }

  get isOpen() {
    return this.args.accordionAPI.openSectionId === this.args.id;
  }

  @action
  close() {
    if (!this.args.isDisabled) {
      this.args.accordionAPI.closeSection(this.args.id);
    }
  }

  @action
  open() {
    if (!this.args.isDisabled) {
      this.args.accordionAPI.openSection(this.args.id);
    }
  }

  @action
  toggle() {
    if (!this.args.isDisabled) {
      this.args.accordionAPI.toggleSection(this.args.id);
    }
  }
}
