import { computed, get } from '@ember/object';
import emberStringFmt from 'ember-string-fmt';
import { cleanFmtStyleValue } from '@intercom/pulse/lib/fmt-style';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

const slice = Array.prototype.slice;

export default function () {
  let params = slice.call(arguments);
  let template = params.shift();
  let keys = params.slice(0);

  params.push(function () {
    let values = [];
    let value;
    let rawValue;
    for (let i = 0; i < keys.length; ++i) {
      rawValue = get(this, keys[i]);
      value = cleanFmtStyleValue(rawValue);
      values.push(value);
    }
    return sanitizeHtml(emberStringFmt(template, values));
  });

  return computed.apply(this, params);
}
