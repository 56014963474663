import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

export default class Form extends Component {
  @tracked hasSubmitted = false;

  @task
  *submit(event) {
    event.preventDefault();
    this.hasSubmitted = true;
    if (this.args.onSubmit) {
      yield this.args.onSubmit();
    }
  }
}
