import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import defaultTo from '@intercom/pulse/lib/default-to';

export default Component.extend({
  tagName: '',
  sortState: defaultTo({}),
  activeSortDirection: alias('sortState.direction'),
  activeSortColumn: alias('sortState.valuePath'),
  sortByString: computed('activeSortColumn', 'activeSortDirection', function () {
    // this string will be passed directly to ember-composable-helpers'
    // `sort-by` helper (used in ic-light-table.hbs) which supports passing a
    // property to sort on as well as an optional `:desc` postfix to invert
    // the sort direction
    if (this.activeSortColumn === undefined) {
      return '';
    } else {
      let sortDirectionString = this.activeSortDirection === 'desc' ? ':desc' : '';
      return `${this.activeSortColumn}${sortDirectionString}`;
    }
  }),
  actions: {
    updateSort(newSortColumn) {
      let activeSortColumnWillNotChange = newSortColumn === this.activeSortColumn;
      let currentSortDirectionIsAscending = this.activeSortDirection === 'asc';
      // the previous and next conditions enforce that the new sort direction
      // can only ever become descending if the current direction is ascending
      // and our active sort column has not changed. This encodes the
      // information that our default sort direction is ascending
      let newSortDirection =
        activeSortColumnWillNotChange && currentSortDirectionIsAscending ? 'desc' : 'asc';
      let newSortState = {
        valuePath: newSortColumn,
        direction: newSortDirection,
      };

      this.set('sortState', newSortState);
    },
  },
});
