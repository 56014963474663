import Component from '@ember/component';
import { action } from '@ember/object';
import { assert, runInDebug } from '@ember/debug';
import { notEmpty, filterBy, empty, none, not, and } from '@ember/object/computed';
import { computed } from '@ember/object';
import { createDropdownGroupFromGroupList } from '@intercom/pulse/lib/dropdown-group';
import { calculateMaxHeight } from '@intercom/pulse/lib/dropdown-utils';

const ACCEPTABLE_MODES = ['block', 'inline'];

export default Component.extend({
  tagName: '',
  mode: 'inline',
  groupListNotEmpty: notEmpty('groupList'),
  groupListEntriesThatAreFilters: filterBy('groupList', 'isFilter', true),
  groupListDoesNotContainFilter: empty('groupListEntriesThatAreFilters'),
  filterPlaceholderIsNotPresent: none('filterPlaceholder'),
  filterPlaceholderIsPresent: not('filterPlaceholderIsNotPresent'),
  filterShouldBeAdded: and(
    'groupListNotEmpty',
    'groupListDoesNotContainFilter',
    'hasFilter',
    'filterPlaceholderIsPresent',
  ),
  groupListWithFilterAddedIfRequired: computed(
    'groupList.[]',
    'filterShouldBeAdded',
    'filterPlaceholder',
    function () {
      let groupList = this.get('groupList');
      if (groupList === undefined) {
        return;
      }
      let groupListCopy = groupList.slice(0);
      if (this.get('filterShouldBeAdded')) {
        groupListCopy.unshift({
          isFilter: true,
          placeholder: this.get('filterPlaceholder'),
        });
      }
      return groupListCopy;
    },
  ),
  groupTree: computed('groupListWithFilterAddedIfRequired.[]', function () {
    return createDropdownGroupFromGroupList(this.get('groupListWithFilterAddedIfRequired'));
  }),
  closeOnSelectItem: true,
  measureOpener: action(function (data) {
    if (!this.isDestroying) {
      this.set('openerWidth', data.state.elements.reference.offsetWidth);
    }
  }),
  calculateMaxHeight: action(function (popperModifierData) {
    if (!this.isDestroying) {
      this.set('maxOverlayHeight', calculateMaxHeight(popperModifierData));
    }
  }),
  selectItem: action(function (itemOrItemsValue) {
    let onSelectItem = this.get('onSelectItem');
    if (typeof onSelectItem === 'function') {
      onSelectItem(itemOrItemsValue);
    }
  }),
  focusItem: action(function (itemOrItemsValue) {
    let onFocusItem = this.get('onFocusItem');
    if (typeof onFocusItem === 'function') {
      onFocusItem(itemOrItemsValue);
    }
  }),
  willRender() {
    this._super(...arguments);
    this.validate();
  },
  validate() {
    runInDebug(() => {
      this.checkForIncorrectUse(
        this.get('hasFilter') && typeof this.get('filterPlaceholder') !== 'string',
        'If you specify that a dropdown has a filter, then you must specify the filterPlaceholder.',
      );

      this.checkForIncorrectUse(
        !ACCEPTABLE_MODES.includes(this.get('mode')),
        `"${this.get('mode')}" is not a valid value for "mode". ${ACCEPTABLE_MODES.join(
          ', ',
        )} are valid values for "mode".`,
        'mode',
      );

      let groupList = this.get('groupList');
      if (groupList === undefined) {
        return;
      }

      this.checkForIncorrectUse(
        groupList === undefined || groupList.length === 0,
        'A groupList should have at least one entry.',
        'groupList',
      );

      groupList.forEach((group) => {
        this.checkForIncorrectUse(
          group.items === undefined && group.component === undefined,
          'Each group in a groupList should have either a list of items or a component specified.',
          'groupList',
        );
        if (typeof group.items === 'object') {
          this.checkForIncorrectUse(
            group.items.find(
              (item) =>
                item.text === undefined && item.component === undefined && item.items === undefined,
            ) !== undefined,
            'Each item in a group should have either its text, items, or a component specified.',
            'groupList',
          );
        }
      });
    });
  },
  checkForIncorrectUse(incorrectlyUsed, message, propertyNameOrCallback) {
    let componentName = this.get('componentName');
    if (componentName === undefined) {
      componentName = 'ic-base-component';
    }
    let assertMessage = `${componentName}: ${message}`;
    if (incorrectlyUsed) {
      console.debug(assertMessage);
      if (typeof propertyNameOrCallback === 'string') {
        let property = this.get(propertyNameOrCallback);
        console.debug(
          `Property "${propertyNameOrCallback}" has a value of`,
          property,
          `with typeof ${typeof property}.`,
        );
      } else if (typeof propertyNameOrCallback === 'function') {
        propertyNameOrCallback();
      }
    }
    assert(assertMessage, !incorrectlyUsed);
  },
});
