import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { assert, runInDebug } from '@ember/debug';
import { ROOT_PARENT_SELECTOR, elementIsChildOfPopover } from '@intercom/pulse/lib/popover-utils';
import { deprecate } from '@ember/debug';

const VALID_WIDTHS = ['s', 'm', 'l', 'xl', 'vw95'];
const VALID_FOCUS_ELEMENTS = ['body', 'primary', 'secondary'];

const isBooleanOrDefault = (value, defaultValue) => {
  return typeof value === 'boolean' ? value : defaultValue;
};

export default class IcModal extends Component {
  @tracked alternateRenderContext;
  @tracked hasCalculatedRenderContext = false;
  id = guidFor(this);

  get deprecatedIsOpen() {
    if ('isOpen' in this.args) {
      deprecate(
        'The <IcModal> @isOpen argument is deprecated. Please use `{{#if this.showModal}}<IcModal />{{/if}}` instead',
        false,
        {
          id: 'ic-modal.isOpen',
          since: '3.19.0',
          until: '4.0',
          // eslint-disable-next-line @intercom/intercom/use-asset-url
          for: '@intercom/pulse',
        },
      );
      return this.args.isOpen;
    }
    return true;
  }

  get closeOnEscape() {
    return isBooleanOrDefault(this.args.closeOnEscape, true);
  }
  get closeOnBlanketClick() {
    return isBooleanOrDefault(this.args.closeOnBlanketClick, true);
  }
  get showCloseIcon() {
    return isBooleanOrDefault(this.args.showCloseIcon, true);
  }
  get internalScroll() {
    return isBooleanOrDefault(this.args.internalScroll, true);
  }
  get width() {
    if (this.args.width === undefined) {
      return 's';
    }
    let suppliedWidthIsValid = VALID_WIDTHS.includes(this.args.width);
    runInDebug(() => {
      assert(
        `<IcModal>: the provided @width argument (${JSON.stringify(
          this.args.width,
        )}) is valid, please choose one of ${JSON.stringify(VALID_WIDTHS)}`,
        suppliedWidthIsValid,
      );
    });
    return this.args.width;
  }
  get focusElement() {
    if (this.args.focusElement === undefined) {
      return 'body';
    }
    let suppliedFocusElementIsValid = VALID_FOCUS_ELEMENTS.includes(this.args.focusElement);
    runInDebug(() => {
      assert(
        `<IcModal>: the provided @focusElement argument (${JSON.stringify(
          this.args.focusElement,
        )}) is valid, please choose one of ${JSON.stringify(VALID_FOCUS_ELEMENTS)}`,
        suppliedFocusElementIsValid,
      );
    });
    return this.args.focusElement;
  }

  get blanketElementSelector() {
    return `[data-modal-blanket="${this.id}"]`;
  }
  get blanketElement() {
    return document.querySelector(this.blanketElementSelector);
  }
  get wrapperElementSelector() {
    return `[data-modal-wrapper="${this.id}"]`;
  }
  get wrapperElement() {
    return document.querySelector(this.wrapperElementSelector);
  }

  @action
  onBlanketClick() {
    if (this.closeOnBlanketClick) {
      this.args.onModalClose();
    }
  }

  // This action is used to calculate if the current modal is the beginning
  // of a popover/overlay tree (aka. doesn't live inside another popover or
  // overlay) allowing us to render the element in the body instead if so
  @action
  setupRenderingContext(element) {
    if (this.args.neverRenderInPlace || !elementIsChildOfPopover(element.parentElement)) {
      this.alternateRenderContext = document.querySelector(ROOT_PARENT_SELECTOR);
    }
    this.hasCalculatedRenderContext = true;
  }
}
