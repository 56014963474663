import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import trustedStyle from '@intercom/pulse/lib/trusted-style';

const HEADER_HEIGHT = 64;
const GRADIENT_HEIGHT = 8;

export default class PulseHeroBanner extends Component {
  @tracked scrollTop = 0;
  @tracked initialDescriptionHeight;

  get headerBackgroundThreshold() {
    if (isNaN(this.scrollTop)) {
      return HEADER_HEIGHT - GRADIENT_HEIGHT;
    } else if (this.scrollTop < GRADIENT_HEIGHT) {
      return HEADER_HEIGHT - this.scrollTop;
    } else if (this.scrollTop > this.initialDescriptionHeight) {
      return HEADER_HEIGHT;
    } else {
      return HEADER_HEIGHT - GRADIENT_HEIGHT;
    }
  }

  get headerStyle() {
    return trustedStyle`background-image: linear-gradient(to bottom, rgba(241, 241, 241, 1) ${this.headerBackgroundThreshold}px, rgba(241, 241, 241, 0) ${HEADER_HEIGHT}px);`;
  }

  @action
  initializeDescriptionHeight(element) {
    this.initialDescriptionHeight = element.offsetHeight;
  }
}
