import { warn } from '@ember/debug';
import emberStringFmt from 'ember-string-fmt';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export const IS_WORD = /^[#A-Za-z0-9-]+$/i;
export const IS_MEASURMENT_UNIT = /^(\d*\.)?\d+(px|pt|em|rem|vw|vh|%|)$/i;

export function cleanFmtStyleValue(rawValue) {
  if (rawValue === undefined || rawValue === null) {
    return '';
  } else if (IS_WORD.test(rawValue)) {
    return rawValue;
  } else if (IS_MEASURMENT_UNIT.test(rawValue)) {
    return rawValue;
  } else {
    let value = parseFloat(rawValue);

    if (isNaN(value)) {
      warn(`'${rawValue}' is not a valid fmtStyle value`, false, {
        id: 'pulse.computed-properties.fmt-string.invalid-value',
      });
      value = '';
    }

    return value;
  }
}

export default function fmtStyle(styleTemplate, ...values) {
  let cleanedValues = values.map((value) => cleanFmtStyleValue(value));
  let cleanedStyleString = emberStringFmt(styleTemplate, cleanedValues);
  return sanitizeHtml(cleanedStyleString);
}
