function _getAutoplayUrlForWistiaId(videoId, autoplay) {
  return `https://fast.wistia.net/embed/iframe/${videoId}?plugin[socialbar-v1][on]=false&controlsVisibleOnLoad=false&videoQuality=hd-only&playerColor=1d364b&smallPlayButton=true&playButton=true&fullscreenButton=true&videoFoam=true&autoPlay=${
    autoplay ? 'true' : 'false'
  }&playerPreference=auto&wmode=transparent`;
}

export function getPlayableUrlForWistiaId(videoId) {
  return _getAutoplayUrlForWistiaId(videoId, false);
}

export function getAutoplayUrlForWistiaId(videoId) {
  return _getAutoplayUrlForWistiaId(videoId, true);
}

export default {
  getPlayableUrlForWistiaId,
  getAutoplayUrlForWistiaId,
};
