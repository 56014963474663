import { htmlSafe } from '@ember/template';
import {
  sanitizeHtml as intercomSanitizeHtml,
  sanitizeUrl as intercomSanitizeUrl,
} from '@intercom/intercom-sanitizer';

export function sanitizeHtml(unsafe, options) {
  // This is a valid usage of `htmlSafe` as we are creating a higher-level utility so others don't use `htmlSafe` directly
  // eslint-disable-next-line @intercom/intercom/no-html-safe
  return htmlSafe(intercomSanitizeHtml(unsafe, options));
}

export function sanitizeUrl(unsafe) {
  // This is a valid usage of `htmlSafe` as we are creating a higher-level utility so others don't use `htmlSafe` directly
  // eslint-disable-next-line @intercom/intercom/no-html-safe
  return htmlSafe(intercomSanitizeUrl(unsafe));
}

export default {
  sanitizeHtml,
  sanitizeUrl,
};
