interface UserAgentDetectorInterface {
  uaString: string;
  vendorString: string;
  isIE: () => boolean;
  isEdge: () => boolean;
  isFirefox: () => boolean;
  isSafari: () => boolean;
  isChrome: () => boolean;
  isGhostInspector: () => boolean;
  browserName: () => string;
}

const UserAgentDetector: UserAgentDetectorInterface = {
  uaString: navigator.userAgent,
  vendorString: navigator.vendor,

  isIE(): boolean {
    return /Trident\/./.test(UserAgentDetector.uaString);
  },

  isEdge(): boolean {
    return /Edge/.test(UserAgentDetector.uaString);
  },

  isFirefox(): boolean {
    return /Gecko\/\d+\sFirefox\/\d+/.test(UserAgentDetector.uaString);
  },

  isSafari(): boolean {
    return (
      /Apple/i.test(UserAgentDetector.vendorString) && /Safari/i.test(UserAgentDetector.uaString)
    );
  },

  isChrome(): boolean {
    return /Chrome/.test(UserAgentDetector.uaString) && !/Edge/i.test(UserAgentDetector.uaString);
  },

  isGhostInspector(): boolean {
    return /Ghost Inspector/.test(UserAgentDetector.uaString);
  },

  browserName(): string {
    let UAD = UserAgentDetector;
    let browsers = {
      ie: UAD.isIE(),
      edge: UAD.isEdge(),
      firefox: UAD.isFirefox(),
      safari: UAD.isSafari(),
      chrome: UAD.isChrome(),
      ghostinspector: UAD.isGhostInspector(),
    };
    let currentBrowser = Object.keys(browsers).find((name) => {
      return browsers[name as keyof typeof browsers];
    });
    return currentBrowser || 'unknown';
  },
};

export default UserAgentDetector;
