import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class TrackAnalyticsEventHelper extends Helper {
  @service intercomEventService;

  compute(_positionalParams, namedParams) {
    return () => {
      this.intercomEventService.trackAnalyticsEvent(namedParams);
    };
  }
}
