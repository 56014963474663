import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  // We yield this empty component just to wrap the ic-button subclass
  // as we want to pass some hardcoded default values to it
  tagName: '',
  isSelected: computed('selectedValue', 'value', function () {
    return this.selectedValue === this.value;
  }),
});
