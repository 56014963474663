let ATTRIBUTE_REGEX = /({{.*?}}|%7B%7B.*?%7D%7D)/gi;
let DOUBLE_QUOTE_FALLBACK_REGEX = /fallback:(\s|%20)*(")(.*?)(")/i;
let SINGLE_QUOTE_FALLBACK_REGEX = /fallback:(\s|%20)*(')(.*?)(')/i;
let ENCODED_QUOTE_FALLBACK_REGEX = /fallback:(\s|%20)*(%22)(.*?)(%22)/i;

let START_CURLIES = /{{|%7B%7B/g;
let START_CURLIES_AT_BEGINNING = /^({{|%7B%7B)/g;
let END_CURLIES = /}}|%7D%7D/g;
let END_CURLIES_AT_END = /(}}|%7D%7D)$/g;
let START_OR_END_SPACES = /(^(\s|%20)*|(\s|%20)*$)/g;
let PIPE = /\||%7C/;

const fallbackRegexes = [
  DOUBLE_QUOTE_FALLBACK_REGEX,
  SINGLE_QUOTE_FALLBACK_REGEX,
  ENCODED_QUOTE_FALLBACK_REGEX,
];

function _getFallback(template: string) {
  let fallback: string | undefined = '';
  fallbackRegexes.some((regex) => {
    let matches = regex.exec(template);
    if (matches) {
      fallback = matches[3];
    }
  });
  return fallback;
}

function _containsTooManyCurlies(template: string) {
  let match = template.match(START_CURLIES);
  return match && match.length > 1;
}

function _replaceOnlyValidAttributes(
  template: string,
  replacerCallback: (
    template: string,
    context?: {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      [key: string]: any;
    },
  ) => string,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  context?: { [key: string]: any },
): string {
  let matchStartCurlies = template.match(START_CURLIES);
  let matchEndCurlies = template.match(END_CURLIES);
  if (matchStartCurlies && matchStartCurlies.length > 1) {
    let indexOfOpeningCurlies =
      template.indexOf('{{') > -1 ? template.indexOf('{{') : template.indexOf('%7B%7B');
    return (
      template.substring(indexOfOpeningCurlies, 2) +
      replacerCallback(template.substring(indexOfOpeningCurlies + 2), context)
    );
  }
  if (matchEndCurlies && matchEndCurlies.length > 1) {
    let indexOfEndingCurlies =
      template.indexOf('}}') > -1 ? template.indexOf('}}') : template.indexOf('%7D%7D');
    return (
      template.substring(indexOfEndingCurlies, 2) +
      replacerCallback(template.substring(indexOfEndingCurlies + 2), context)
    );
  }
  return '';
}

function _replaceSingleAttributeWithContext(singleContext: string) {
  return function (matchedText: string) {
    if (_containsTooManyCurlies(matchedText)) {
      return matchedText;
    }
    return singleContext;
  };
}

function _containsPipe(text: string) {
  return PIPE.test(text);
}

function _getKeyBeforePipe(text: string) {
  if (text.match(/\|/)) {
    return text.split('|')[0] as string;
  }
  if (text.match(/%7C/)) {
    return text.split('%7C')[0] as string;
  }
  return text;
}

function _getKey(text: string) {
  let key = text.replace(START_CURLIES_AT_BEGINNING, '').replace(END_CURLIES_AT_END, '');
  if (_containsPipe(key)) {
    key = _getKeyBeforePipe(key);
  }
  return key.replace(START_OR_END_SPACES, '');
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function _insertContext(template: string, context?: { [key: string]: any }) {
  return template.replace(ATTRIBUTE_REGEX, function (match) {
    if (_containsTooManyCurlies(match)) {
      return _replaceOnlyValidAttributes(match, _insertContext, context);
    }
    let key = _getKey(match);
    if (context && context[key]) {
      let regexToFind = `(\\{\\{\\s*${key}.*?\\}\\}|%7B%7B(%20)*${key}.*?7D%7D)`;
      return match.replace(
        new RegExp(regexToFind),
        _replaceSingleAttributeWithContext(context[key]),
      );
    }
    return _replaceAttributesWithFallback(match);
  });
}

function _replaceAttributesWithFallback(template: string) {
  return template.replace(ATTRIBUTE_REGEX, function (matchedText) {
    //Does not replace in the case where there are unfinished attributes
    //{{ first_name some other text {{ last_name }}
    if (_containsTooManyCurlies(matchedText)) {
      return _replaceOnlyValidAttributes(template, _replaceAttributesWithFallback);
    }
    return _getFallback(matchedText);
  });
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function renderTemplate(template: string, context: { [key: string]: any }): string {
  return _insertContext(template, context);
}

export default renderTemplate;
