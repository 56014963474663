import { htmlSafe } from '@ember/template';
import { warn } from '@ember/debug';
import { IS_WORD, IS_MEASURMENT_UNIT } from '@intercom/pulse/lib/fmt-style';

export default function trustedStyle(literals, ...placeholders) {
  let saferString = '';
  for (let i = 0; i < placeholders.length; i++) {
    let literal = literals[i];
    let placeholder = placeholders[i];
    let placeholderString = String(placeholder);

    saferString += literal;

    if (placeholder === undefined || placeholder === null) {
      //skip
    } else if (IS_MEASURMENT_UNIT.test(placeholderString) || IS_WORD.test(placeholderString)) {
      saferString += placeholder;
    } else {
      warn(`'${placeholder}' is not a valid trustedStyle value`, false, {
        id: 'pulse.trustedStyle.invalid-value',
      });
    }
  }

  // Add the final literal
  saferString += literals[literals.length - 1];

  // This is a valid usage of `htmlSafe` as we are creating a higher-level utility so others don't use `htmlSafe` directly
  // eslint-disable-next-line @intercom/intercom/no-html-safe
  return htmlSafe(saferString);
}
