import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class IcModalHeaderHeading extends Component {
  @action
  onTitleChange(element, [title]) {
    if (element.offsetWidth < element.scrollWidth) {
      element.title = title;
    } else {
      delete element.title;
    }
  }
}
