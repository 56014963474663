import { get } from '@ember/object';

export default function getWithZeroDefault(context, key) {
  let value = get(context, key);
  if (value === undefined) {
    return 0;
  } else {
    return value;
  }
}
