import { get, computed } from '@ember/object';

export default function filterByProperty(dependentKey, propertyKey, valueKey) {
  return computed(`${dependentKey}.@each.${propertyKey}`, valueKey, function () {
    let collection = get(this, dependentKey);
    if (!collection || !collection.filterBy) {
      return [];
    }
    return collection.filterBy(propertyKey, this.get(valueKey));
  });
}
