import { debug, assert } from '@ember/debug';
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';

const RGB_HEXCODE_REGEXP = new RegExp(/^[0-9a-f]{3}$|^[0-9a-f]{6}$/i);
const STARTS_WITH_HASH_REGEXP = new RegExp(/^#/);
const VALID_SIZES = ['xs', 's', 'm', 'l', 'xl'];
const VALID_SHAPES = ['circle', 'squircle'];

let defaultAvatarData = {
  url: DefaultAvatars.users[128],
  isCompany: false,
  isCustomer: true,
  isTeammate: false,
  isTeam: false,
  showAsActive: false,
  showAsAway: false,
  deleted: false,
  avatarShape: 'circle',
};

let defaultOptions = {
  size: 'l',
  contained: false,
  withText: false,
  canAnimate: false,
  displayAdminBadge: false,
};

let optionsWithUndefinedAsDefault = ['onBackgroundColor'];

export function avatarShapeClass(avatarShape) {
  if (VALID_SHAPES.includes(avatarShape) && avatarShape !== 'circle') {
    return `o__shape-${avatarShape}`;
  }
}

function avatarFallbackUrl(avatarData, options) {
  let sizeToUse;
  if (options !== undefined && ['xs', 's', 'm'].includes(options.size)) {
    sizeToUse = 64;
  } else {
    sizeToUse = 128;
  }
  if (avatarData.isTeammate) {
    return DefaultAvatars.admins[sizeToUse];
  } else if (avatarData.isCompany) {
    return DefaultAvatars.companies[sizeToUse];
  } else {
    return DefaultAvatars.users[sizeToUse];
  }
}

function checkAvatarData(originalAvatarData, options) {
  if (originalAvatarData === undefined) {
    debug('This object does not expose avatar data, and thus cannot have an avatar rendered.');
    return defaultAvatarData;
  }

  let avatarData = Object.assign({}, originalAvatarData);
  if (
    typeof avatarData.color === 'string' &&
    avatarData.color.match(STARTS_WITH_HASH_REGEXP) !== null
  ) {
    avatarData.color = avatarData.color.slice(1);
  }
  if (!(avatarData.color === undefined || avatarData.color.match(RGB_HEXCODE_REGEXP))) {
    assert('Avatar color, if specified, must be a valid hex value.');
    avatarData.color = undefined;
  }

  if (avatarData.deleted || (avatarData.isCompany && avatarData.color === undefined)) {
    avatarData.url = avatarFallbackUrl(avatarData, options);
  }

  // Add this key for easy canAnimate use
  avatarData.shouldAnimateUpdate =
    avatarData !== undefined &&
    options !== undefined &&
    options.canAnimate &&
    avatarData.displayAsDidUpdate;

  return avatarData;
}

function formatOptions(unformattedOptions) {
  let options = {};
  let defaultOptionsKeys = Object.keys(defaultOptions);
  for (let i = 0, l = defaultOptionsKeys.length; i < l; i++) {
    let optionName = defaultOptionsKeys[i];
    options[optionName] =
      unformattedOptions[optionName] === undefined
        ? defaultOptions[optionName]
        : unformattedOptions[optionName];
  }
  if (!VALID_SIZES.includes(options.size)) {
    assert('You may only use a supported avatar size.');
    options.size = defaultOptions.size; // Prevent injection here
  }
  for (let i = 0, l = optionsWithUndefinedAsDefault.length; i < l; i++) {
    let optionName = optionsWithUndefinedAsDefault[i];
    if (unformattedOptions[optionName] !== undefined) {
      options[optionName] = unformattedOptions[optionName];
    }
  }

  return options;
}

function shouldRenderAvatarBadge(avatarData, displayAdminBadge) {
  return (
    (avatarData.isCustomer || (avatarData.isTeammate && displayAdminBadge)) && !avatarData.deleted
  );
}

function checkAvatarTypeToRender(avatarData) {
  if (avatarData.isCompany && typeof avatarData.color === 'string') {
    return 'company';
  }
  return avatarData.isCustomer &&
    typeof avatarData.url !== 'string' &&
    typeof avatarData.initials === 'string' &&
    avatarData.initials.length > 0 &&
    typeof avatarData.color === 'string'
    ? 'text'
    : 'image';
}

function formatInitials(initials) {
  return typeof initials === 'string' ? initials.slice(0, 2) : undefined;
}

function avatarIconPath(size) {
  return `avatars/company/${size}`;
}

export default {
  checkAvatarData,
  formatOptions,
  shouldRenderAvatarBadge,
  checkAvatarTypeToRender,
  avatarFallbackUrl,
  formatInitials,
  defaultAvatarData,
  defaultOptions,
  avatarIconPath,
  avatarShapeClass,
};
