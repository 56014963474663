import { get, computed } from '@ember/object';

export default function flatMap(arrayKey, mapByKey) {
  return computed(arrayKey, `${arrayKey}.@each.${mapByKey}`, function () {
    let array = get(this, arrayKey).mapBy(mapByKey);
    return array.reduce(function (initial, current) {
      return initial.concat(current.toArray());
    }, []);
  });
}
